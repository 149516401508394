import { useState, useEffect } from 'react';
import WebSocketClient from 'websocket';

function useAWSSocket(localMedia) {
	const [client, setClient] = useState();
	const [localClient, setLocalClient] = useState();

	useEffect(() => {
		if (localMedia && !localClient) {
			startSocket();
		}
	}, [localMedia]);

	useEffect(() => {
		if (localClient) {
			localClient.onopen = () => {
				setClient(localClient);
				setInterval(() => {
					ping(localClient);
				}, 60 * 1000 * 7);
			};
		}
	}, [localClient]);

	const startSocket = () => {
		// setLocalClient(
		// 	new WebSocketClient.w3cwebsocket(
		// 		'wss://j0bip9ycyh.execute-api.eu-west-1.amazonaws.com/dev'
		// 	)
		// );
	};

	const ping = (_client) => {
		_client.send(
			JSON.stringify({
				action: 'ping',
			})
		);
	};

	return client;
}

export default useAWSSocket;
