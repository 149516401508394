import { useState, useEffect } from 'react';

function useSocketConnection(socket, localStream, room, name) {
	const [streamConnections, setStreamConnections] = useState([]);
	const [connectionsState, setConnectionsState] = useState([]);
	let sharingReady = false;

	let connections = [];
	const offerOptions = {
		offerToReceiveVideo: 1,
	};
	const mediaStreamConstraints = {
		iceServers: [
			{
				urls: 'stun:stun.l.google.com:19302',
			},
		],
		video: true,
		audio: true,
	};

	useEffect(() => {
		if (socket) {
			socket.emit('set-name', name);
			socket.emit('join-room', room);
			listenSocket();
		}
	}, [socket]);

	const handleError = (e, fromId) => {
		console.log(`useSocketConnection error from id: ${fromId}`, e);
	};

	const gotRemoteStream = (event, userId) => {
		const user = {
			stream: event.stream,
			userId,
		};
		streamConnections.push(user);
		setStreamConnections([...streamConnections]);
	};

	const gotIceCandidate = (fromId, candidate) => {
		connections[fromId]
			.addIceCandidate(new RTCIceCandidate(candidate))
			.catch((e) => {
				handleError(e, fromId)
			});
	};

	const gotMessageFromSignaling = (socket, data) => {
		const fromId = data.fromId;
		if (fromId !== socket.id) {
			switch (data.type) {
				case 'candidate':
					if (data.candidate) {
						gotIceCandidate(fromId, data.candidate);
					}
					break;

				case 'sdp':
					if (data.description) {
						connections[fromId]
							.setRemoteDescription(
								new RTCSessionDescription(data.description)
							)
							.then(() => {
								if (data.description.type === 'offer') {
									connections[fromId]
										.createAnswer()
										.then((description) => {
											connections[fromId]
												.setLocalDescription(
													description
												)
												.then(() => {
													socket.emit('signaling', {
														type: 'sdp',
														toId: fromId,
														description:
															connections[fromId]
																.localDescription,
													});
												});
										})
										.catch(handleError);
								}
							})
							.catch(handleError);
					}
					break;
			}
		}
	};

	const listenSocket = async () => {
		socket.on('user-joined', (data) => {
			const clients = data.clients;
			const joinedUserId = data.joinedUserId;
			if (Array.isArray(clients) && clients.length > 0) {
				clients.forEach((userId) => {
					if (!connections[userId]) {
						connections[userId] = new RTCPeerConnection(
							mediaStreamConstraints
						);						
						connections[userId].onicecandidate = (event) => {							
							if (event.candidate) {
								socket.emit('signaling', {
									type: 'candidate',
									candidate: event.candidate,
									toId: userId,
								});
							}
						};
						connections[userId].onaddstream = (event) => {							
							gotRemoteStream(event, userId);
						};
						connections[userId].addStream(localStream);
						setConnectionsState(connections);
					}
				});

				if (data.count >= 2) {
					connections[joinedUserId]
						.createOffer(offerOptions)
						.then((description) => {							
							connections[joinedUserId]
								.setLocalDescription(description)
								.then(() => {
									socket.emit('signaling', {
										toId: joinedUserId,
										description:
											connections[joinedUserId]
												.localDescription,
										type: 'sdp',
										myName: name,
									});
								})
								.catch(handleError);
						})
						.catch(handleError);
				}
			}
		});

		socket.on('user-left', (userId) => {
			setStreamConnections([
				...streamConnections.filter((x) => x.userId !== userId),
			]);
		});

		socket.on('signaling', (data) => {
			gotMessageFromSignaling(socket, data);
		});
	};

	return streamConnections;
}

export default useSocketConnection;
