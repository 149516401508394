import { selector } from 'recoil';

import {
	shareStreamState,
	shareCanvasImgState,
	uploadedImagesState,
} from './atom';

export const isShareActiveSelector = selector({
	key: 'isShareActiveSelector',
	get: ({ get }) => {
		const shareStream = get(shareStreamState);
		const canvasImg = get(shareCanvasImgState);
		return shareStream != null || canvasImg !== null;
	},
});

export const addNewFile = selector({
	key: 'addNewFile',
	get: ({ get }) => get(uploadedImagesState),
	set: ({ get, set }, newElement) => {
		const current = get(uploadedImagesState);
		const newValue = [newElement, ...current];
		set(uploadedImagesState, newValue);
	},
});
