import { atom } from 'recoil';

import { CAMERA_TYPE } from './../../constants';

export const cameraControlState = atom({
	key: 'cameraControlState',
	default: false,
});

export const cameraViewTypeState = atom({
	key: 'cameraViewTypeState',
	default: CAMERA_TYPE.DEFAULT,
});

export const currentCameraLayoutState = atom({
	key: 'currentCameraLayoutState',
	default: {
		type: CAMERA_TYPE.HIGHLIGHTED_USERS,
		highlightedUsers: [],
	},
});

export const highlightedUsersState = atom({
	key: 'highlightedUsersState',
	default: [],
});

export const isShareHighlightedState = atom({
	key: 'isShareHighlightedState',
	default: true,
});
