import { atom } from 'recoil';

export const clientState = atom({
	key: 'clientState',
	default: undefined,
});

export const roomConnectionsState = atom({
	key: 'roomConnections',
	default: [],
});

export const roomClientsState = atom({
	key: 'roomClientsState',
	default: [],
});

export const streamConnectionsState = atom({
	key: 'streamConnectionsState',
	default: [],
	dangerouslyAllowMutability: true,
});

export const remoteMediaStreamsState = atom({
	key: 'remoteMediaStreams',
	default: [],
});

export const remoteNamesState = atom({
	key: 'remoteNamesState',
	default: [],
});

export const remoteAvatarsState = atom({
	key: 'remoteAvatarsState',
	default: [],
});

export const remoteVideosPlayingState = atom({
	key: 'remoteVideosPlayingState',
	default: [],
});

export const remoteAudiosPlayingState = atom({
	key: 'remoteAudiosPlayingState',
	default: [],
});
