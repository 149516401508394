export const CAMERA_TYPE = {
	DEFAULT: 1,
	HIGHLIGHTED_USERS: 2,
};
export const CHAT_EVENTS = {
	DICE_RESULT: 1,
	SHARING: 2,
	CLEAR_DICE_RESULTS: 3,
	USER_NAME: 4,
	CAMERA_LAYOUT: 5,
	SHARE_S3_IMAGE: 6,
	SHARE_S3_AUDIO: 7,
	AVATAR: 8,
	VIDEO_STATUS: 9,
	MIC_STATUS: 10,
	CHAT: 11,
	CHAT_WARNING: 12,
};
export const CLIENT_EVENTS = {
	ENTER_ROOM: 1,
	SIGNALING: 2,
	STREAM_READY: 3,
	CHAT_READY: 4,
	INCOMING_CHAT: 5,
	ROOM_UPDATE: 6,
	RESYNC_USER: 7,
	DELETE_USER: 8,
};
export const DICE_TYPES = {
	NORMAL: 1,
	L5R: 2,
	RESULT: 3,
	RESULT_SUCCESS: 4,
	RESULT_FAIL: 5,
	CUSTOM: 6,
};
export const FAKE_STREAM = 'fake_stream';
export const IS_LOCALHOST = window.location.hostname === 'localhost';
export const SIZES = {
	small: 24,
	medium: 40,
	big: 100,
};
export const USER_STREAM_STATUS = {
	CREATED: 0,
	SYNC: 1,
	ESTABLISHED: 2,
};

export const IMAGE_MIMES = ['image/jpeg', 'image/png', 'image/jpg'];
export const AUDIO_MIMES = ['audio/mpeg'];

export const LIBRARY_TYPE = {
	PUBLIC: 0,
	PRIVATE: 1,
	RULES: 2,
};

export const LIBRARY_ITEMS_TYPES = {
	FOLDER: 1,
	FILE: 2,
	CREATE_FOLDER: 3,
};

export const EMPTY_LIBRARY = {
	name: '',
	dirty: true,
	type: LIBRARY_ITEMS_TYPES.FOLDER,
	children: [],
};

export const EMPTY_FOLDER = {
	name: '',
	dirty: true,
	children: [],
	type: LIBRARY_ITEMS_TYPES.FOLDER,
};

export const APIS = {
	FILE_MANAGER: {
		token: 'Pvz1bmckcp7BY2fXTW5Su8H7TPp6EWFF7haEqXXk',
		urls: {
			uploadImage: 'https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/imageUpload',
			getS3PresignedPost: (userId, extension) =>
				`https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/getS3PresignedPostUrl?userId=${userId}&extension=${extension}`,
			getSignedFile: (key) =>
				`https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/getSignedFile?key=${key}`,
			getLibraryFile: (key, userId) =>
				`https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/library?key=${key}${
					userId ? `&user_id=${userId}` : ''
				}`,
			createLibrary: 'https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/library',
		},
	},
	LIBRARY_MANAGER: {
		token: 'NkscQ254D45FRWQlQc9cc45wrW3IJoBx1HRx6zi6',
		urls: {
			createLibrary: 'https://zl79sxq7fe.execute-api.eu-west-1.amazonaws.com/dev/libraries',
			getLibraries: (userId) =>
				`https://zl79sxq7fe.execute-api.eu-west-1.amazonaws.com/dev/libraries?userId=${userId}`,
		},
	},
	AVATAR_MANAGER: {
		token: 'y3RIqMqLMT8GpUCEW7G986vQdUCMiMTM54TZKtpS',
		urls: {
			uploadAvatar: 'https://h5tvh2rbg8.execute-api.eu-west-1.amazonaws.com/dev/createAvatar',
			getUserAvatars: (userId) =>
				`https://h5tvh2rbg8.execute-api.eu-west-1.amazonaws.com/dev/avatars?userId=${userId}`,
			deleteAvatar: (id) =>
				`https://h5tvh2rbg8.execute-api.eu-west-1.amazonaws.com/dev/avatars/${id}`,
		},
	},
};

export const SLEEP = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
