import React, { useEffect, useState } from 'react';
import { makeStyles, Badge, Box, IconButton } from '@material-ui/core';
import { Cancel, MusicNote } from '@material-ui/icons';
import { useRecoilValue, useRecoilState } from 'recoil';

import { sharedAudiosState } from './../../common/recoil/Share/atom';
import { CHAT_EVENTS } from './../../common/constants';
import { useChat } from './../../common/hooks';

const useStyles = makeStyles((theme) => ({
	button: {
		// position: 'absolute',
		// right: 0,
		// zIndex: 100000,
		margin: 0,
		padding: 0,
	},
	musicArea: {
		position: 'fixed',
		width: '300px',
		zIndex: 100000,
	},
	musicElement: {
		display: 'flex',
	},
	audio: {
		width: '350px',
	},
}));

const AudioTooltip = () => {
	const classes = useStyles();
	const [sharedAudios, setSharedAudios] = useRecoilState(sharedAudiosState);
	const [open, setOpen] = useState(false);
	const [playingAudios, setPlayingAudios] = useState([]);
	const [alreadyOpened, setAlreadyOpened] = useState(false);
	const { messages, sendMessage } = useChat();

	useEffect(() => {
		if (sharedAudios?.length > 0 && !alreadyOpened) {
			setAlreadyOpened(true);
			setOpen(true);
			//document.querySelectorAll(`audio`).play();
		}
		const audios = document.querySelectorAll(`audio`);
		[].forEach.call(audios, function (x) {
			// do whatever
			x.play();
			x.volume = 0.2;
		});
	}, [sharedAudios]);

	const handleClick = (event) => {
		setOpen(!open);
	};

	const handlePlay = (event, other) => {};
	const handlePause = (event, other) => {};
	const ended = (event, other) => {};

	const removeMusic = (id) => {
		const tempAudios = [...sharedAudios?.filter((x) => x.id !== id)];
		setSharedAudios(tempAudios);

		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_AUDIO,
			sharedAudio: tempAudios,
		};
		sendMessage(shareImgMessage);
	};

	return (
		<Box>
			<IconButton
				className={classes.button}
				aria-describedby="shareMusic"
				type="button"
				disabled={sharedAudios?.length === 0}
				onClick={handleClick}>
				<Badge badgeContent={sharedAudios?.length || 0} color="error">
					<MusicNote></MusicNote>
				</Badge>
			</IconButton>
			<Box className={classes.musicArea} visibility={open ? 'visible' : 'hidden'}>
				{sharedAudios?.map((audio) => {
					return (
						<Box className={classes.musicElement}>
							<audio
								id={audio.id}
								key={audio.id}
								loop="true"
								controls
								autoplay
								onPlay={handlePlay}
								onPause={handlePause}
								onEnded={ended}
								className={classes.audio}>
								<source src={audio.url} type={audio.mime}></source>
							</audio>
							<IconButton color="secondary">
								<Cancel
									onClick={() => {
										removeMusic(audio.id);
									}}></Cancel>
							</IconButton>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default AudioTooltip;
