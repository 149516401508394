import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import {
	localShareActiveState,
	shareStreamState,
} from '../../common/recoil/Share/atom';

function useShare() {
	const [share, setShare] = useRecoilState(shareStreamState);
	const [shareActive, setShareActive] = useRecoilState(localShareActiveState);

	const [constraints, setConstraints] = useState({
		video: true,
		audio: true,
	});

	useEffect(() => {
		if (shareActive) {
			const getMediaStream = async () => {
				try {
					const mediaStream =
						await navigator.mediaDevices.getDisplayMedia(
							constraints
						);
					await setShare(mediaStream);
				} catch (error) {
					console.error('error', error);
					setShareActive(false);
				}
			};
			getMediaStream();
		} else if (share) {
			share.getTracks().forEach((track) => {
				console.log('stop track', track);
				track.stop();
			});
			setShare(null);
		}
	}, [shareActive]);

	return share;
}

export default useShare;
