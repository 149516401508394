import { atom } from 'recoil';

export const dicesSelectedState = atom({
	key: 'dicesSelectedState',
	default: [],
});

export const dicesBonusListState = atom({
	key: 'dicesBonusListState',
	default: [],
});

export const customDicesState = atom({
	key: 'customDicesState',
	default: [],
});

export const customDiceSelectedState = atom({
	key: 'customDiceSelectedState',
	default: '',
});

export const isNextDiceExplosiveState = atom({
	key: 'isNextDiceExplosiveState',
	default: false,
});

export const isNextDiceRepetitionState = atom({
	key: 'isNextDiceRepetitionState',
	default: false,
});

export const repeatDicesState = atom({
	key: 'repeatDicesState',
	default: false,
});

export const areRulesOpenState = atom({
	key: 'areRulesOpenState',
	default: false,
});
