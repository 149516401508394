import { useEffect } from 'react';

function useRoom(client) {
	useEffect(() => {
		if (client) {
			client.onopen = () => {
				enterInRoom();
				setInterval(() => {
					ping();
				}, 60 * 1000 * 7);
			};
		}
	}, []);

	const enterInRoom = () => {
		client.send(
			JSON.stringify({
				action: 'room',
				username: 'username',
				message: 'some weird message',
			})
		);
	};

	const ping = () => {
		client.send(
			JSON.stringify({
				action: 'ping',
			})
		);
	};

	return enterInRoom;
}

export default useRoom;
