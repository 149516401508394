import React from 'react';
import { Box } from '@material-ui/core';
import PubSub from 'pubsub-js';

import { CLIENT_EVENTS, USER_STREAM_STATUS } from './../../common/constants';

class UserStream extends React.Component {
	mediaStreamConstraints = {
		iceServers: [
			{
				urls: 'stun:stun.l.google.com:19302',
			},
		],
		video: true,
		audio: true,
	};
	offerOptions = {
		offerToReceiveVideo: 1,
	};

	constructor(props) {
		super(props);
		console.log('props are ', props);
		console.log('this.props are ', this.props);
		this.state = { logs: [] };
		this.createStream();
		if (this.props.status === 'active') {
			// console.log('use data', this.props.initialData);
			// this.gotMessageFromSignaling('internal', this.props.initialData);
			this.pong();
		} else {
			// this.establishConnection();
			this.ping();
		}
		PubSub.subscribe(CLIENT_EVENTS.SIGNALING, this.gotMessageFromSignaling);
	}

	createStream() {
		this.stream = new RTCPeerConnection(this.mediaStreamConstraints);
		this.status = USER_STREAM_STATUS.CREATED;
		// ICE CANDIDATE
		this.stream.onicecandidate = (event) => {
			if (event.candidate) {
				this.sendCandidate(event);
			}
		};

		// EVENT ADD STREAM
		this.stream.onaddstream = (event) => {
			console.log('!!! onaddstream', event);
			this.stream = event.stream;
			this.status = USER_STREAM_STATUS.ESTABLISHED;
			this.props.addStream(this.props.remoteId, this.stream);
		};

		this.stream.onconnectionstatechange = (event) => {
			console.log('Updated onconnectionstatechange', event);
			this.addLog('onconnectionstatechange');
		};

		this.stream.onicegatheringstatechange = (event) => {
			this.addLog('onicegatheringstatechange');
		};

		this.stream.onsignalingstatechange = (event) => {
			this.addLog('onsignalingstatechange');
		};

		this.stream.oniceconnectionstatechange = (event) => {
			this.addLog('oniceconnectionstatechange');
			if (
				this.stream.connectionState === 'failed' &&
				this.stream.iceConnectionState === 'disconnected'
			) {
				//userDisconnected(userStream.id);
			}
		};

		this.stream.onnegotiationneeded = (event) => {
			this.addLog('onnegotiationneeded');
			// if (userStream.status !== USER_STREAM_STATUS.CREATED) {
			// 	establishConnection(userStream);
			// }
		};
	}

	addLog(event) {
		const log = {
			connectionState: this.stream.connectionState,
			event: event,
			iceConnectionState: this.stream.iceConnectionState,
			iceGatheringState: this.stream.iceGatheringState,
			signalingState: this.stream.signalingState,
		};
		const logs = [...this.state.logs];
		logs.push(log);
		console.log('add new log', log);
		console.log('the logs', logs);
		this.setState({ logs: logs });
	}

	establishConnection() {
		this.status = USER_STREAM_STATUS.SYNC;

		// CREATE OFFER
		this.stream
			.createOffer(this.offerOptions)
			.then((description) => {
				this.stream
					.setLocalDescription(description)
					.then(() => {
						this.props.client.send(
							JSON.stringify({
								action: 'signaling',
								description: this.stream.localDescription,
								type: 'sdp',
								toId: this.props.remoteId,
								fromId: this.props.id,
							})
						);
					})
					.catch(this.handleError);
			})
			.catch(this.handleError);
	}

	gotMessageFromSignaling(message, data) {
		console.log('!!!!got message from signaling');
		console.log('message', message);
		console.log('data', data);
		if (data.toId !== this.props.remoteId) {
			return;
		}
		this.status = USER_STREAM_STATUS.SYNC;
		switch (data.type) {
			case 'candidate':
				if (data.candidate) {
					this.gotIceCandidate(data.candidate);
				}
				break;
			case 'pong':
				console.log('!!!!!!!!!!!!!!!!!!!!!!!!pong');
				this.establishConnection();
				break;
			case 'sdp':
				if (data.description) {
					this.stream
						.setRemoteDescription(
							new RTCSessionDescription(data.description)
						)
						.then(() => {
							if (data.description.type === 'offer') {
								this.stream
									.createAnswer()
									.then((description) => {
										this.stream
											.setLocalDescription(description)
											.then(() => {
												this.props.client.send(
													JSON.stringify({
														action: 'signaling',
														description: this.stream
															.localDescription,
														type: 'sdp',
														toId: this.props
															.remoteId,
														fromId: this.props.id,
													})
												);
											});
									})
									.catch(this.handleError);
							}
						})
						.catch(this.handleError);
				}
				break;
		}
	}

	gotIceCandidate(candidate) {
		this.stream
			.addIceCandidate(new RTCIceCandidate(candidate))
			.catch((e) => {
				console.log('error on gotIceCandidate', e);
				console.log('error value candidate', candidate);
				//this.handleError
			});
	}
	handleError = (error) => {
		console.log('error handled', error);
	};

	render() {
		return (
			<Box>
				<Box>My ID: {this.props.id}</Box>
				<Box>Remote ID: {this.props.remoteId}</Box>
			</Box>
		);
	}

	ping() {
		console.log('ping to', this.props.remoteId);
		this.props.client.send(
			JSON.stringify({
				action: 'signaling',
				type: 'ping',
				toId: this.props.remoteId,
				fromId: this.props.id,
			})
		);
	}

	pong() {
		console.log('pong to', this.props.remoteId);
		this.props.client.send(
			JSON.stringify({
				action: 'signaling',
				type: 'pong',
				toId: this.props.remoteId,
				fromId: this.props.id,
			})
		);
	}

	sendCandidate(event) {
		console.log('send candidate', {
			action: 'signaling',
			type: 'candidate',
			candidate: event.candidate,
			toId: this.props.remoteId,
			fromId: this.props.id,
		});
		this.props.client.send(
			JSON.stringify({
				action: 'signaling',
				type: 'candidate',
				candidate: event.candidate,
				toId: this.props.remoteId,
				fromId: this.props.id,
			})
		);
	}
}

export default UserStream;
