import React, { memo, useEffect, useRef, useState } from 'react';
import { makeStyles, Avatar, Box, IconButton } from '@material-ui/core';
import { Mic, MicOff, Videocam, VideocamOff } from '@material-ui/icons/';
import { useRecoilValue, useRecoilState } from 'recoil';

import { FAKE_STREAM } from './../../common/constants';
import { microOnState, userNameState, videoOnState } from './../../common/recoil/UserInfo/atoms';
import { remoteNamesState } from './../../common/recoil/websocket/atoms';
import {
	currentCameraLayoutState,
	isShareHighlightedState,
} from './../../common/recoil/Cameras/atom';
import { customDiceSelectedState } from '../../common/recoil/GameSystem/gameSystemAtom';
import { DiceIcons, DiceResult, DiceResultAlert, StreamMenu } from './../../components';
import { IS_LOCALHOST } from './../../common/constants';
import UserCameraCustomDices from './UserCameraCustomDices';

const useStyles = makeStyles((theme) => ({
	cameraWrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	cameraBorder: {
		background: 'blue',
	},
	video: {
		// maxWidth: '100%',
		// maxHeight: '100%',
	},
	infoArea: {
		//height: '100px',
		height: '20%',
		position: 'absolute',
		bottom: 0,
		background: 'white',
		//background: 'rgba(0, 0, 0, 0.2)',
	},
	inlineName: {
		position: 'absolute',
		color: 'white',
		textAlign: 'center',
		backgroundImage: 'url(/images/name-background.png)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		height: '64px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '10px',
	},
	inlineNameSideBar: {
		position: 'absolute',
		color: 'white',
		textAlign: 'center',
		backgroundImage: 'url(/images/name-background.png)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '5px',
	},
	inlineAvatar: {
		position: 'absolute',
		margin: theme.spacing(2),
	},
	inlineDices: {
		position: 'absolute',
		'&::-webkit-scrollbar': {
			width: '0.4em',
			height: '0.1px',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.3)',
			outline: '1px solid rgba(255,255,255,.3)',
		},
		overflow: 'auto',
		height: '100%',
		display: 'flex',
		flexDirection: 'column-reverse',
	},
	inlineMediaStreamButtonsBig: {
		background: 'black',
		color: 'white',
		marginTop: '5px',
		marginLeft: '5px',
	},
	inlineMediaStreamButtonsSmall: {
		background: 'black',
		color: 'white',
		marginTop: '5px',
		marginLeft: '5px',
		width: '5px',
		height: '5px',
		'& svg': {
			width: '20px',
			height: '20px',
		},
	},
	dice: {
		background: "url('/images/d10.png') no-repeat center transparent",
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		height: '50px',
		width: '100px',
		backgroundSize: 'contain',
		color: 'white',
	},
	newDice: {
		position: 'relative',
	},
	newDiceSvg: {
		color: 'black',
	},
	newDiceSpan: {
		color: 'white',
		background: 'black',
		position: 'absolute',
		fontSize: '16px',
		left: '10px',
		top: '10px',
		width: '19px',
		textAlign: 'center',
		borderRadius: '50px',
	},
	sidebarAvatar: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
	avatar: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginBottom: '5px',
	},
	h1Name: {
		fontSize: '40px',
		fontFamily: "'Amatic SC',	cursive",
		//fontFamily: "'Architects Daughter', cursive",
		//fontFamily: "'Teko', sans-serif",
	},
	h1NameSidebar: {
		fontSize: '20px',
		fontFamily: "'Amatic SC',	cursive",
		//fontFamily: "'Architects Daughter', cursive",
		//fontFamily: "'Teko', sans-serif",
	},
}));

const UserCameraContainer = ({
	avatarUrl,
	diceResults,
	mediaStream,
	userId,
	isShare,
	isLocalUser,
	remoteUserName,
	useCameraClass = 'camera',
	isSideBar = false,
	videoPlaying = true,
	isMicPlaying = true,
}) => {
	const classes = useStyles();
	const videoRef = useRef();
	const wrapperRef = useRef();

	const [microOn, setMicroOn] = useRecoilState(microOnState);
	const [videoOn, setVideoOn] = useRecoilState(videoOnState);
	const [isFake, setIsFake] = useState(false);
	const [cameraTopPosition, setCamperaTopPosition] = useState(0);
	const [cameraBottomPosition, setCamperaBottomPosition] = useState(0);
	const [cameraLeftPosition, setCamperaLeftPosition] = useState(0);
	const [cameraRightPosition, setCamperaRgihtPosition] = useState(0);
	const [cameraWidth, setCameraWidth] = useState('100%');
	const [cameraHeight, setCameraHeight] = useState('100%');
	const [infoWidth, setInfoWidth] = useState('100%');
	const [avatarBigWidth, setAvatarBigWidth] = useState('100%');
	const userName = useRecoilValue(userNameState);
	const remoteNames = useRecoilValue(remoteNamesState);
	const isShareHighlighted = useRecoilValue(isShareHighlightedState);
	const currentCameraLayout = useRecoilValue(currentCameraLayoutState);
	const customDiceSelected = useRecoilValue(customDiceSelectedState);
	const views = {
		view1: {
			info: 'inside',
		},
		view2: {
			info: 'outside',
		},
		view3: {
			info: 'inline',
		},
	};

	const selectedView = views.view3;

	const updatePositions = () => {
		const streamWidth = mediaStream?.getVideoTracks()[0]?.getSettings()?.width;
		const streamHeight = mediaStream?.getVideoTracks()[0]?.getSettings()?.height;
		const containerWidth = wrapperRef?.current?.clientWidth || 0;
		const containerHeight = wrapperRef?.current?.clientHeight || 0;

		const newHeight = (containerWidth / streamWidth) * streamHeight;
		const newWidth = (containerHeight / streamHeight) * streamWidth;

		if (containerHeight > newHeight) {
			setCamperaTopPosition(`${Math.abs(containerHeight - newHeight) / 2}px`);
		} else {
			setCamperaTopPosition(0);
		}

		setAvatarBigWidth(`${Math.min(newHeight, newWidth) / 2}px`);

		if (isSideBar) {
			setInfoWidth('100%');
			setCamperaLeftPosition('0');
			const newInfoWidth = Math.min(newWidth, containerWidth);

			setInfoWidth(`${newInfoWidth}px`);
			setCamperaLeftPosition(`${(containerWidth - newInfoWidth) / 2}px`);
		} else if (streamWidth) {
			const newInfoWidth = Math.min(newWidth, containerWidth);

			setInfoWidth(`${newInfoWidth}px`);
			setCamperaLeftPosition(`${(containerWidth - newInfoWidth) / 2}px`);
		}
	};

	useEffect(() => {
		if (mediaStream && mediaStream.type !== FAKE_STREAM) {
			videoRef.current.srcObject = mediaStream;
			//updateSize();
			updatePositions();
			try {
				mediaStream.oninactive = () => {
					console.log(`end streaming ${userId}`);
				};
			} catch (e) {
				console.error('Error setting oninactive mediastream', e);
			}

			if (IS_LOCALHOST && isLocalUser) {
				stopCamera();
				stopAudio();
			}
			setInterval(updatePositions, 10000);
		} else if (mediaStream && mediaStream.type === FAKE_STREAM) {
			setIsFake(true);
		}
	}, [mediaStream]);

	// useEffect(() => {
	// 	const observer = new ResizeObserver((entries) => {
	// 		if (mediaStream) {
	// 			updateSize();
	// 		}
	// 	});
	// 	observer.observe(wrapperRef.current);
	// 	return () => wrapperRef.current && observer.unobserve(wrapperRef.current);
	// }, []);

	useEffect(() => {
		if (mediaStream) {
			//updateSize();
			updatePositions();
			setTimeout(updatePositions, 1);
		}
	}, [remoteNames, isSideBar, currentCameraLayout, isShareHighlighted]);

	const handleCanPlay = () => {
		videoRef.current.play();
	};

	const stopCamera = () => {
		mediaStream.getVideoTracks()[0].enabled = !mediaStream.getVideoTracks()[0].enabled;
		setVideoOn(!videoOn);
	};

	const stopAudio = () => {
		mediaStream.getAudioTracks()[0].enabled = !mediaStream.getAudioTracks()[0].enabled;
		setMicroOn(!microOn);
	};

	const renderInfo = () => {
		return (
			<Box width={infoWidth} className={classes.infoArea}>
				<h1>{isLocalUser ? userName : remoteUserName}</h1>
				{avatarUrl && (
					<Avatar
						className={isSideBar ? classes.sidebarAvatar : classes.avatar}
						src={avatarUrl}
					/>
				)}
				<Box>
					{diceResults?.map((result, index) => {
						if (result) {
							if (Array.isArray(result)) {
								return result.map((x, subIndex) => {
									return (
										<Box
											className={classes.newDice}
											key={`dice-${index}-${subIndex}`}>
											<DiceIcons dice={x.notation} size="medium" />
											<span className={classes.newDiceSpan}>
												<DiceResult
													notation={x.notation}
													result={x.value}
													type={x.type}
												/>
											</span>
										</Box>
									);
								});
							} else if (result instanceof Object) {
								return (
									<Box className={classes.newDice} key={`dice-${index}`}>
										<DiceIcons dice={result.notation} size="medium" />
										<span className={classes.newDiceSpan}>
											<DiceResult
												notation={result.notation}
												result={result.value}
												type={result.type}
											/>
										</span>
									</Box>
								);
							} else {
								return <Box className={classes.dice}> {result} </Box>;
							}
						}
					})}
				</Box>
				<DiceResultAlert
					results={diceResults}
					isLocalUser={isLocalUser}
					userName={isLocalUser ? userName : remoteUserName}></DiceResultAlert>
			</Box>
		);
	};

	const renderInlineInfo = () => {
		return (
			<>
				{!isLocalUser && (
					<StreamMenu
						overridenTop={cameraTopPosition}
						overridenRight={cameraLeftPosition}
						userId={userId}
					/>
				)}
				<Box
					bottom={cameraTopPosition}
					className={isSideBar ? classes.inlineNameSideBar : classes.inlineName}
					width={infoWidth}>
					<h1 className={isSideBar ? classes.h1NameSidebar : classes.h1Name} m={0}>
						{isLocalUser ? userName : remoteUserName}
					</h1>
				</Box>

				{avatarUrl && videoPlaying && (
					<Box
						bottom={cameraTopPosition}
						right={cameraLeftPosition}
						className={classes.inlineAvatar}>
						<Avatar
							className={isSideBar ? classes.sidebarAvatar : classes.avatar}
							src={avatarUrl}
						/>
					</Box>
				)}
				<Box left={cameraLeftPosition} className={classes.inlineDices}>
					<UserCameraCustomDices diceResults={diceResults} />
				</Box>
				<Box top={cameraTopPosition} position="absolute">
					<IconButton
						className={
							isSideBar
								? classes.inlineMediaStreamButtonsSmall
								: classes.inlineMediaStreamButtonsBig
						}
						color="secondary"
						aria-label="upload picture"
						component="span"
						onClick={() => {
							if (isLocalUser) stopAudio();
						}}>
						{isMicPlaying ? <Mic></Mic> : <MicOff></MicOff>}
					</IconButton>
					{isLocalUser && (
						<IconButton
							className={
								isSideBar
									? classes.inlineMediaStreamButtonsSmall
									: classes.inlineMediaStreamButtonsBig
							}
							color="secondary"
							aria-label="upload picture"
							component="span"
							onClick={() => {
								stopCamera();
							}}>
							{isLocalUser &&
								(videoOn ? <Videocam></Videocam> : <VideocamOff></VideocamOff>)}
						</IconButton>
					)}
				</Box>
				<DiceResultAlert
					results={diceResults}
					isLocalUser={isLocalUser}
					userName={isLocalUser ? userName : remoteUserName}></DiceResultAlert>
			</>
		);
	};

	return (
		<Box className={useCameraClass} display="flex" justifyContent="center">
			<Box ref={wrapperRef} className={classes.cameraWrapper}>
				{/* <Box className={classes.border}>
					<video
						className={classes.video}
						ref={videoRef}
						onCanPlay={handleCanPlay}
						autoPlay
						playsInline
						muted={isLocalUser === true}
					/>
				</Box> */}
				<video
					height={cameraHeight}
					width={cameraWidth}
					className={classes.video}
					ref={videoRef}
					onCanPlay={handleCanPlay}
					autoPlay
					playsInline
					muted={isLocalUser === true}
				/>
				{!videoPlaying && avatarUrl && (
					<Box
						position="absolute"
						width={avatarBigWidth}
						height={avatarBigWidth}
						borderRadius="50%"
						display="flex"
						overflow="hidden"
						style={{
							backgroundImage: `url(${avatarUrl})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}>
						{/* <img src={avatarUrl} />{' '} */}
					</Box>
				)}
				{selectedView.info === 'inside' && renderInfo()}
				{selectedView.info === 'inline' && renderInlineInfo()}
			</Box>
			{selectedView.info === 'outside' && renderInfo()}
		</Box>
	);
};

export default UserCameraContainer;
