import React, { useState } from 'react';
import { makeStyles, Box, Grid, IconButton, Typography } from '@material-ui/core';
import { CreateNewFolder } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { LIBRARY_TYPE, LIBRARY_ITEMS_TYPES } from '../../../common/constants';
import LibraryItem from './LibraryItem';
import CreateFolderDialog from './CreateFolderDialog';
import CreateLibraryDialog from './CreateLibraryDialog';
import {
	currentLibraryBreadCrumbState,
	currentLibraryTypeState,
	showCreateFolderDialogState,
	showCreateLibraryDialogState,
	publicLibrariesState,
	privateLibrariesState,
	rulesLibraryState,
} from '../../../common/recoil/Library/libraryAtom';
import {
	currentLibraryContentSelector,
	currentFolderSelector,
} from '../../../common/recoil/Library/librarySelector';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		margin: '10px',
	},
}));

const LibraryFolders = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [currentTab, setCurrentTab] = useState(2);
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [currentLibraryType, setCurrentLibraryType] = useRecoilState(currentLibraryTypeState);
	const [showCreateLibraryDialog, setShowCreateLibraryDialog] = useRecoilState(
		showCreateLibraryDialogState
	);
	const [showCreateFolderDialog, setShowCreateFolderDialog] = useRecoilState(
		showCreateFolderDialogState
	);
	const [publicLibraries, setPublicLibraries] = useRecoilState(publicLibrariesState);
	const [privateLibraries, setPrivateLibraries] = useRecoilState(privateLibrariesState);
	const [rulesLibrary, setRuleLibrary] = useRecoilState(rulesLibraryState);

	const [currentFolder, setCurrentFolder] = useRecoilState(currentFolderSelector);
	const isLibraryLevel = !currentLibraryBreadCrumb || currentLibraryBreadCrumb?.length === 0;
	const currentList = isLibraryLevel
		? currentLibraryType == LIBRARY_TYPE.PUBLIC
			? publicLibraries
			: currentLibraryType == LIBRARY_TYPE.PRIVATE
			? privateLibraries
			: rulesLibrary
		: currentFolder?.children || [];

	const columnsSize = currentList?.length === 0 ? '12' : currentList?.length === 1 ? '6' : '4';

	return (
		<Box className={classes.root}>
			<Typography>{t('LibrariesManager.content.folders')}</Typography>
			<Grid container spacing={4}>
				{currentList.map((x) => (
					<Grid item xs={columnsSize}>
						<LibraryItem title={x.name} type={x.type} file={x} />
					</Grid>
				))}
				<Grid item xs={columnsSize}>
					<LibraryItem
						title={t('LibrariesManager.content.createFolder')}
						type={LIBRARY_ITEMS_TYPES.CREATE_FOLDER}
						isLibraryLevel={isLibraryLevel}
					/>
				</Grid>
			</Grid>
			<CreateFolderDialog />
			<CreateLibraryDialog />
		</Box>
	);
};

export default LibraryFolders;
