import { EMPTY_FOLDER, LIBRARY_ITEMS_TYPES } from '../constants';

export const findAndAddElement = (
	currentLibrary,
	currentBreadcrumb,
	name,
	showError,
	type = LIBRARY_ITEMS_TYPES.FOLDER,
	file
) => {
	if (currentBreadcrumb.length === 0) {
		if (type === LIBRARY_ITEMS_TYPES.FOLDER) {
			currentLibrary = addFolderToLibrary(currentLibrary, name, showError);
		} else if (type === LIBRARY_ITEMS_TYPES.FILE) {
			currentLibrary = addFileToLibrary(currentLibrary, name, file, showError);
		}
	} else {
		let childFolder = getChildrenFolder(currentLibrary, currentBreadcrumb[0]);
		childFolder = findAndAddElement(
			childFolder,
			currentBreadcrumb.slice(1),
			name,
			showError,
			type,
			file
		);
		currentLibrary = updateChildren(currentLibrary, currentBreadcrumb[0], childFolder);
	}
	return currentLibrary;
};

const getChildrenFolder = (currentLibrary, nextStep) => {
	return currentLibrary.children.find((x) => x.name === nextStep);
};

const updateChildren = (currentLibrary, name, child) => {
	const newChild = currentLibrary.children.map((x) => {
		if (x.name === name) {
			return child;
		} else {
			return x;
		}
	});

	const newCurrentLibary = { ...currentLibrary };

	newCurrentLibary.children = newChild;
	return newCurrentLibary;
};

const addFolderToLibrary = (currentLibrary, name, showError) => {
	if (!checkElementExists(currentLibrary, name)) {
		const newFolder = { ...EMPTY_FOLDER };
		newFolder.name = name;
		currentLibrary = { ...currentLibrary };
		currentLibrary.children = [...currentLibrary.children];
		currentLibrary.children.push(newFolder);
	} else {
		try {
			showError();
		} catch (e) {
			console.log(e);
		}
	}
	return currentLibrary;
};

const checkElementExists = (container, name) => {
	return container?.children.some((x) => x.name === name);
};

const addFileToLibrary = (currentLibrary, name, file, showError) => {
	if (!checkElementExists(currentLibrary, name)) {
		currentLibrary = { ...currentLibrary };
		currentLibrary.children = [...currentLibrary.children];
		const newFile = { ...file };
		newFile.name = name;
		newFile.type = LIBRARY_ITEMS_TYPES.FILE;
		currentLibrary.children.push(newFile);
	} else {
		try {
			showError();
		} catch (e) {
			console.log(e);
		}
	}
	return currentLibrary;
};
