import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, CssBaseline, Divider, Drawer, IconButton } from '@material-ui/core';
import {
	AccountCircle,
	AddBox,
	ChevronLeft,
	ChevronRight,
	ExitToApp,
	RemoveCircle,
	ScreenShare,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	CamerasBoard,
	ControlList,
	DiceSelector,
	FileManager,
	LibrariesManager,
	Portal,
	Tabletop,
	UserInfoSelector,
} from './../../components';
import { CHAT_EVENTS } from './../../common/constants';
import { useBroadcastChannel, useChat } from './../../common/hooks';
import { remoteDiceResultsState } from '../../common/recoil/chatMessages/atoms';
import { localShareActiveState, showImageUploadState } from './../../common/recoil/Share/atom';
import { isShareActiveSelector } from './../../common/recoil/Share/selectors';
import { diceResultsState, diceRollState, userIdState } from './../../common/recoil/UserInfo/atoms';
import ControlBoard from './../../pages/ControlBoard/ControlBoard';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
	},
	bottomNavigation: {
		width: '100%',
		height: '100%',
	},
}));

export default function ControlDrawer({ client }) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [showPortal, setShowPortal] = useState(false);
	const [openDiceSelector, setOpenDiceSelector] = useState(false);
	const [openUserInfoSelector, setOpenUserInfoSelector] = useState(false);

	const [localShareActive, setLocalShareActive] = useRecoilState(localShareActiveState);
	const [remoteDiceResults, setRemoteDiceResults] = useRecoilState(remoteDiceResultsState);
	const [openUploadFiles, setOpenUploadFiles] = useRecoilState(showImageUploadState);
	const [diceResults, setDiceResults] = useRecoilState(diceResultsState);
	const isShareActive = useRecoilValue(isShareActiveSelector);
	const id = useRecoilValue(userIdState);
	const [roll, setRoll] = useRecoilState(diceRollState);
	const { t, i18n } = useTranslation();
	const { messages, sendMessage } = useChat(id);

	const handleToggle = () => {
		setOpen(!open);
	};

	const manageRoll = (value) => {
		if (roll === 0) {
			setRoll(value);
		}

		setOpen(false);
	};

	const clearDiceResults = () => {
		setDiceResults([]);
		const clearDiceResultsMessage = {
			type: CHAT_EVENTS.CLEAR_DICE_RESULTS,
			id: id,
		};
		sendMessage(clearDiceResultsMessage);
	};

	const openControlBoard = () => {
		setShowPortal(true);
		//window.open(`/?control=true&userid=${id}`, 'Horrol Control');
	};

	const closeControlBoard = () => {
		setShowPortal(false);
	};

	window.addEventListener('beforeunload', () => setShowPortal(false));

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}>
				<IconButton onClick={handleToggle}>
					{open ? <ChevronLeft /> : <ChevronRight />}
				</IconButton>
				<Divider />
				<ControlList
					clearDiceResults={clearDiceResults}
					openControlBoard={openControlBoard}
					setOpenDiceSelector={setOpenDiceSelector}
					setOpenUploadFiles={setOpenUploadFiles}
					setOpenUserInfoSelector={setOpenUserInfoSelector}
					showPortal={showPortal}
					manageRoll={manageRoll}></ControlList>
			</Drawer>
			<main className={classes.content}>
				<Tabletop client={client}></Tabletop>
			</main>
			<DiceSelector open={openDiceSelector} setOpen={setOpenDiceSelector} />
			<UserInfoSelector open={openUserInfoSelector} setOpen={setOpenUserInfoSelector} />
			<FileManager />
			{/* <React.Suspense fallback={<div>Loading...</div>}>
				<LibrariesManager />
			</React.Suspense> */}
			{showPortal && (
				<Portal
					showPortal={showPortal}
					onClose={closeControlBoard}
					pageTitle="Horrol Portal">
					<ControlBoard externalClasses={classes}></ControlBoard>
				</Portal>
			)}
		</div>
	);
}
