import React, { useMemo, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { DICE_TYPES } from './../../common/constants';
import {
	customDicesState,
	customDiceSelectedState,
	dicesBonusListState,
	dicesSelectedState,
	isNextDiceExplosiveState,
	isNextDiceRepetitionState,
	repeatDicesState,
} from '../../common/recoil/GameSystem/gameSystemAtom';
import { diceResultsState, diceRollState } from '../../common/recoil/UserInfo/atoms';

const CustomDiceResults = ({ result }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [customDices, setCustomDices] = useRecoilState(customDicesState);
	const [customDiceSelected, setCustomDiceSelected] = useRecoilState(customDiceSelectedState);
	const [diceSelected, setDiceSelected] = useRecoilState(dicesSelectedState);
	const [dicesBonusList, setDicesBonusList] = useRecoilState(dicesBonusListState);
	const [diceResults, setDiceResults] = useRecoilState(diceResultsState);
	const [isNextDiceExplosive, setIsNextDiceExplosive] = useRecoilState(isNextDiceExplosiveState);
	const [isNextDiceRepetition, setIsNextDiceRepetition] =
		useRecoilState(isNextDiceRepetitionState);
	const [repeatDices, setRepeatDices] = useRecoilState(repeatDicesState);

	const [roll, setRoll] = useRecoilState(diceRollState);

	const currentCustomDiceConfig = useMemo(() => {
		return customDiceSelected ? customDices.find((x) => x.name === customDiceSelected) : [];
	}, [customDices, customDiceSelected]);

	const sortArray = (arr) => {
		return (arr = [...arr].sort((a, b) => a - b));
	};

	const max = (arr) => {
		arr = sortArray(arr);
		return arr[arr.length];
	};
	const min = (arr) => {
		arr = sortArray(arr);
		return arr[0];
	};
	const median = (arr, low = false) => {
		arr = sortArray(arr);
		let middle = Math.floor(arr.length / 2);
		if (low) {
			middle -= 1;
		}
		return arr[middle];
	};

	const hasExactNumber = (arr, expectedValue) => {
		return arr.some((x) => x === expectedValue);
	};

	const sum = (...args) => {
		const sumRes = args.reduce(
			(tempSum, dicesGroup) =>
				tempSum + dicesGroup.reduce((partialSum, a) => partialSum + a, 0),
			0
		);
		return sumRes;
	};

	const onExplodeDices = (key, explosiveNotations) => {
		closeSnackbar(key);
		setIsNextDiceExplosive(true);
		setRoll(explosiveNotations);
	};

	const showExplosiveMessage = (results, dicesInputFaces, values) => {
		const matches = [];

		for (let i = 0; i <= results.length; i++) {
			const match = values.some((x) => results[i] === x);
			if (match) {
				matches.push(dicesInputFaces[i]);
			}
		}

		if (matches.length >= 1) {
			setTimeout(() => {
				enqueueSnackbar(`Hay ${matches.length} dados relanzables. ¿Quieres lanzarlos?`, {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					action: (key) => (
						<Button
							onClick={() => {
								onExplodeDices(key, matches);
							}}>
							Lanzar
						</Button>
					),
				});
			}, 1500);
		}
	};

	const applyOutputDiceAction = (
		currentResult,
		dicesResult,
		{ action, condition, value, values },
		dicesInputFaces
	) => {
		let foundResult;
		switch (action) {
			case 'find':
				switch (condition) {
					case 'max':
						foundResult = max(dicesResult);
						break;
					case 'min':
						foundResult = min(dicesResult);
						break;
					case 'middle':
						foundResult = median(dicesResult);
						break;
					case 'middleH':
						foundResult = median(dicesResult);
						break;
					case 'middleL':
						foundResult = median(dicesResult, true);
						break;
					case 'exactNumber':
						foundResult = hasExactNumber(dicesResult, value)
							? value
							: currentResult.value;
						break;
				}
				currentResult.value = foundResult;
				break;
			case 'explode':
				showExplosiveMessage(dicesResult, dicesInputFaces, values || []);
				break;
			case 'sum':
				switch (condition) {
					case 'all':
						foundResult = sum(
							dicesResult,
							diceResults
								.filter((dice) => dice.type === DICE_TYPES.NORMAL)
								.map((dice) => dice.value)
						);
						break;
					case 'prevCustom':
						foundResult = sum(dicesResult, [
							diceResults
								.filter((dice) => dice.type === DICE_TYPES.CUSTOM)
								.map((dice) => dice.value)[0],
						]);
						break;
				}
				currentResult.value = foundResult;
				break;
		}

		return currentResult;
	};

	const applyOutputModifier = (currentResult, action, modifierName) => {
		const currentModifier = dicesBonusList.find((x) => x.name === modifierName);

		try {
			switch (action) {
				case 'apply':
					if (isNextDiceExplosive) {
						break;
					}
					currentResult.value = eval(
						`${currentModifier?.value || 0}${currentModifier?.operation || '+'}${
							currentResult.value
						}`
					);
					break;
				case 'difficulty':
					if (currentModifier?.value && currentModifier.operation) {
						currentResult.success = eval(
							`${currentResult.value}${currentModifier.operation}${currentModifier.value}`
						);
						currentResult.partialSuccess = eval(
							`${currentResult.value}${currentModifier.operation}(${
								currentModifier.value
							} - ${currentModifier.errorRange || 0})`
						);
					}
					break;
			}
		} catch (e) {
			console.error('error trying to apply modifier', e);
		}

		return currentResult;
	};

	const clickableDices = (currentResult, action) => {
		switch (action) {
			case 'repeat':
				currentResult.repeatable = true;
				break;
		}
		return currentResult;
	};

	const getDicesOutput = (dicesInputFaces, dicesResult) => {
		return currentCustomDiceConfig.output.map((outputs) => {
			const bonus = [];
			dicesBonusList.forEach((x) => {
				bonus[x.name] = x.value;
			});
			let actions;
			if (isNextDiceExplosive) {
				actions = outputs.explosion.filter((action) => {
					let shouldHappen = action.default !== undefined ? action.default : true;
					if (action.if && Object.keys(bonus).length > 0) {
						let tempShouldHappen = true;
						action.if.forEach((x) => {
							const bonusValue = bonus[x.name] || false;
							if (bonusValue !== x.expectedValue) {
								tempShouldHappen = false;
							}
						});
						shouldHappen = tempShouldHappen;
					}

					return shouldHappen;
				});
			} else {
				actions = outputs.actions.filter((action) => {
					let shouldHappen = action.default !== undefined ? action.default : true;
					if (action.if && Object.keys(bonus).length > 0) {
						let tempShouldHappen = true;
						action.if.forEach((x) => {
							const bonusValue = bonus[x.name] || false;
							if (bonusValue !== x.expectedValue) {
								tempShouldHappen = false;
							}
						});
						shouldHappen = tempShouldHappen;
					}

					return shouldHappen;
				});
			}

			return actions.reduce(
				(currentResult, currentProperty) => {
					switch (currentProperty.type) {
						case 'dice':
							return applyOutputDiceAction(
								currentResult,
								dicesResult,
								currentProperty,
								dicesInputFaces
							);
						case 'modify':
							return applyOutputModifier(
								currentResult,
								currentProperty.action,
								currentProperty.name
							);
						case 'clickable':
							return clickableDices(currentResult, currentProperty.action);
					}
				},
				{
					id: uuidv4(),
					notation: outputs.notation,
					value: 0,
					type: DICE_TYPES.CUSTOM,
					date: new Date().getTime(),
				}
			);
		});
	};

	useEffect(() => {
		if (result && customDiceSelected && Array.isArray(roll)) {
			const dicesInputFaces = currentCustomDiceConfig.input
				.filter((input) => input.type === 'dice')
				.flatMap((input) => {
					const quantity = input.quantity ? parseInt(input.quantity) : 1;
					return Array(quantity).fill(input.value);
				});

			let tempDiceResults = result;
			let tempRoll = roll;

			if (isNextDiceRepetition) {
				const notReroledDices = diceResults.filter(
					(x) =>
						!repeatDices.some((y) => y.id === x.id) &&
						x.type !== DICE_TYPES.CUSTOM &&
						x.date === repeatDices[0].date
				);
				tempDiceResults = [...result, ...notReroledDices.map((x) => x.value)];

				tempRoll = [...roll, ...notReroledDices.map((x) => x.notation)];
			}

			const dicesOutput = getDicesOutput(dicesInputFaces, tempDiceResults);

			const newResult = tempRoll?.map((notation, i) => {
				return {
					date: new Date().getTime(),
					id: uuidv4(),
					notation,
					repeatable: dicesOutput[0]?.repeatable || false,
					type: DICE_TYPES.NORMAL,
					value: tempDiceResults[i],
				};
			});

			newResult.push(...dicesOutput);

			if (isNextDiceExplosive) {
				setIsNextDiceExplosive(false);
				setDiceResults([...newResult, ...diceResults]);
			} else if (isNextDiceRepetition) {
				setIsNextDiceRepetition(false);
				setDiceResults([...newResult, ...diceResults]);
			} else {
				setDiceResults([...newResult]);
			}

			setTimeout(() => {
				setRoll(0);
			}, 1000);
		}
	}, [result]);

	return <></>;
};

export default CustomDiceResults;
