import React from 'react';
import { makeStyles, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import {
	AccountCircle,
	AddBox,
	Chat,
	Description,
	ExitToApp,
	FolderShared,
	LibraryMusic,
	PhotoLibrary,
	Policy,
	RemoveCircle,
	ScreenShare,
	StopScreenShare,
	ViewModule,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AudioTooltip, DiceIcons } from './../index';
import { cameraControlState } from './../../common/recoil/Cameras/atom';
import { isChatOpenState } from './../../common/recoil/chatMessages/atoms';
import {
	areRulesOpenState,
	customDiceSelectedState,
} from '../../common/recoil/GameSystem/gameSystemAtom';
import {
	localShareActiveState,
	musicShareActiveState,
	showFileManagerState,
} from './../../common/recoil/Share/atom';
import { showLibrariesManagerState } from './../../common/recoil/Library/libraryAtom';
import { isShareActiveSelector } from './../../common/recoil/Share/selectors';
import { showImageUploadState } from './../../common/recoil/Share/atom';
import { userIdState, logsOpenState } from './../../common/recoil/UserInfo/atoms';

const useStyles = makeStyles(() => ({
	list: {
		display: 'flex',
		flexDirection: 'row',
	},
}));

export default function ControlDrawer({
	clearDiceResults,
	openControlBoard,
	manageRoll,
	setOpenDiceSelector,
	setOpenUserInfoSelector,
	showPortal,
	isBottomMenu = false,
}) {
	const classes = useStyles();
	const [areRulesOpen, openRules] = useRecoilState(areRulesOpenState);
	const [localShareActive, setLocalShareActive] = useRecoilState(localShareActiveState);
	const [musicShareActive, setMusicShareActive] = useRecoilState(musicShareActiveState);
	const [showFileManager, setShowFileManager] = useRecoilState(showFileManagerState);
	const [showLibrariesManager, setShowLibrariesManager] =
		useRecoilState(showLibrariesManagerState);
	const [cameraControl, setCameraControl] = useRecoilState(cameraControlState);
	const [logsOpen, setLogsOpen] = useRecoilState(logsOpenState);
	const [showImageUpload, setShowImageUpload] = useRecoilState(showImageUploadState);
	const isShareActive = useRecoilValue(isShareActiveSelector);
	const [isChatOpen, setIsChatOpen] = useRecoilState(isChatOpenState);
	const id = useRecoilValue(userIdState);
	const customDiceSystem = useRecoilValue(customDiceSelectedState);
	const { t, i18n } = useTranslation();

	const diceValues = [4, 6, 8, 10, 12, 20, 100];

	const toggleCamperaControl = () => {
		setCameraControl(!cameraControl);
	};

	return (
		<List className={`${isBottomMenu && classes.list}`}>
			{openControlBoard && (
				<>
					<ListItem
						key="account"
						button
						onClick={() => {
							setOpenUserInfoSelector(true);
						}}>
						<ListItemIcon>
							<AccountCircle />
						</ListItemIcon>
						{!isBottomMenu && (
							<ListItemText primary={t('gameControl.options.account')} />
						)}
					</ListItem>
					<ListItem
						key="Chat"
						button
						onClick={() => {
							setIsChatOpen(!isChatOpen);
						}}>
						<ListItemIcon>
							<Chat />
						</ListItemIcon>
						<ListItemText primary={t('gameControl.options.chat')} />
					</ListItem>
					{!isShareActive && (
						<ListItem
							key="share"
							button
							onClick={() => {
								setLocalShareActive(true);
							}}>
							<ListItemIcon>
								<ScreenShare />
							</ListItemIcon>
							{!isBottomMenu && (
								<ListItemText primary={t('gameControl.options.share')} />
							)}
						</ListItem>
					)}
					{localShareActive && (
						<ListItem
							key="stop-share"
							button
							onClick={() => {
								setLocalShareActive(false);
							}}>
							<ListItemIcon>
								<StopScreenShare />
							</ListItemIcon>
							{!isBottomMenu && (
								<ListItemText primary={t('gameControl.options.stopshare')} />
							)}
						</ListItem>
					)}
					{/* {!musicShareActive && (
				<ListItem
					key="musicshare"
					button
					onClick={() => {
						setMusicShareActive(true);
					}}>
					<ListItemIcon>
						<LibraryMusic />
					</ListItemIcon>
					{!isBottomMenu && (
						<ListItemText
							primary={t('gameControl.options.musicShare')}
						/>
					)}
				</ListItem>
			)} */}
					<ListItem key="audioShareTooltip" button>
						<Tooltip title={t('gameControl.options.playingMusing')}>
							<ListItemIcon>
								<AudioTooltip></AudioTooltip>
							</ListItemIcon>
						</Tooltip>

						{!isBottomMenu && (
							<ListItemText primary={t('gameControl.options.playingMusing')} />
						)}
					</ListItem>
					<ListItem
						key="fileShare"
						button
						onClick={() => {
							//setShowFileManager(true);
							setShowLibrariesManager(!showLibrariesManager);
						}}>
						<Tooltip title={t('gameControl.options.folderShared')}>
							<ListItemIcon>
								<FolderShared />
							</ListItemIcon>
						</Tooltip>
						{!isBottomMenu && (
							<ListItemText primary={t('gameControl.options.folderShared')} />
						)}
					</ListItem>
					{diceValues.map((value) => {
						return (
							<ListItem
								key={`dice-value-${value}`}
								button
								onClick={() => {
									manageRoll(value);
								}}>
								<ListItemIcon>
									<DiceIcons dice={value} size="small" />
								</ListItemIcon>
								{!isBottomMenu && (
									<ListItemText
										primary={`${t('gameControl.options.rollDice')} ${value}`}
									/>
								)}
							</ListItem>
						);
					})}
					<ListItem
						key="roll-multiple-dices"
						button
						onClick={() => {
							setOpenDiceSelector(true);
						}}>
						<ListItemIcon>
							<AddBox />
						</ListItemIcon>
						{!isBottomMenu && (
							<ListItemText primary={t('gameControl.options.rollDices')} />
						)}
					</ListItem>
					<ListItem key="clear" button onClick={clearDiceResults}>
						<ListItemIcon>
							<RemoveCircle />
						</ListItemIcon>
						{!isBottomMenu && (
							<ListItemText primary={t('gameControl.options.clearDices')} />
						)}
					</ListItem>
					<ListItem
						key="logs"
						button
						onClick={() => {
							setLogsOpen(true);
						}}>
						<ListItemIcon>
							<Policy />
						</ListItemIcon>
						{!isBottomMenu && <ListItemText primary={t('gameControl.options.logs')} />}
					</ListItem>
				</>
			)}
			{openControlBoard && (
				<ListItem key="exitToApp" button onClick={openControlBoard}>
					<ListItemIcon disabled={showPortal}>
						<ExitToApp color={showPortal ? 'disabled' : 'action'} />
					</ListItemIcon>
					<ListItemText primary={t('gameControl.options.openControlBoard')} />
				</ListItem>
			)}
			{customDiceSystem === 'Robotta' && (
				<ListItem
					key="rules"
					button
					onClick={() => {
						openRules(true);
					}}>
					<ListItemIcon disabled={showPortal}>
						<Description />
					</ListItemIcon>
					<ListItemText primary={t('gameControl.options.openRules')} />
				</ListItem>
			)}
			{isBottomMenu && (
				<ListItem key="ViewModule" button onClick={toggleCamperaControl}>
					<ListItemIcon>
						<ViewModule />
					</ListItemIcon>
				</ListItem>
			)}
			{isBottomMenu && (
				<ListItem
					key="UploadFile"
					button
					onClick={() => {
						setShowImageUpload(true);
					}}>
					<ListItemIcon>
						<PhotoLibrary />
					</ListItemIcon>
					<ListItemText primary={t('gameControl.options.uploadFile')} />
				</ListItem>
			)}
		</List>
	);
}
