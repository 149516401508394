import { atom } from 'recoil';

import { IS_LOCALHOST } from './../../constants';

export const userIdState = atom({
	key: 'userIdState',
	default: '',
});

export const userNameState = atom({
	key: 'userNameState',
	default: IS_LOCALHOST ? `LOCAL-${Math.ceil(Math.random() * 100)}` : '',
});

export const userAvatarState = atom({
	key: 'userAvatarState',
	default: null,
});

export const diceRollState = atom({
	key: 'diceRollState',
	default: 0,
});

export const diceResultsState = atom({
	key: 'diceResultsState',
	default: [],
});

export const isL5RDiceState = atom({
	key: 'isL5RDiceState',
	default: false,
});

export const microOnState = atom({
	key: 'microOnState',
	default: true,
});

export const videoOnState = atom({
	key: 'videoOnState',
	default: true,
});

export const logsOpenState = atom({
	key: 'logsOpenState',
	default: false,
});

export const logsState = atom({
	key: 'logsState',
	default: [],
});
