import { useState, useEffect } from 'react';
import PubSub from 'pubsub-js';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CLIENT_EVENTS, CHAT_EVENTS } from '../constants';
import { chatmessagesState, remoteChatState } from './../recoil/chatMessages/atoms';
import { shareCanvasImgState, sharedAudiosState } from './../recoil/Share/atom';
import {
	userAvatarState,
	userNameState,
	userIdState,
	videoOnState,
	microOnState,
} from './../recoil/UserInfo/atoms';

function useChat() {
	const [subscriptionChatReady, setSubscriptionChatReady] = useState();
	const [remoteChat, setRemoteChat] = useRecoilState(remoteChatState);
	const [messages, setMessages] = useRecoilState(chatmessagesState);
	const [name, setName] = useRecoilState(userNameState);
	const avatar = useRecoilValue(userAvatarState);
	const isLocalVideoPlaying = useRecoilValue(videoOnState);
	const isMicPlaying = useRecoilValue(microOnState);
	const id = useRecoilValue(userIdState);
	const sharedImage = useRecoilValue(shareCanvasImgState);
	const sharedAudios = useRecoilValue(sharedAudiosState);

	useEffect(() => {
		if (!subscriptionChatReady) {
			setSubscriptionChatReady(PubSub.subscribe(CLIENT_EVENTS.CHAT_READY, gotRemoteStream));
		}
	}, []);

	useEffect(() => {
		(async function parseConnections() {
			if (subscriptionChatReady) {
				PubSub.unsubscribe(subscriptionChatReady);
			}
			setSubscriptionChatReady(PubSub.subscribe(CLIENT_EVENTS.CHAT_READY, gotRemoteStream));
		})();
		sentMyName();

		if (sharedImage) {
			sentImage();
		}
		sentMyAvatar();
	}, [remoteChat]);

	useEffect(() => {
		if (remoteChat) {
			sentMyName();
		}
	}, [name]);

	useEffect(() => {
		sentMyAvatar();
	}, [avatar]);

	useEffect(() => {
		if (isLocalVideoPlaying !== undefined) {
			sentMyVideoStatus();
		}
	}, [isLocalVideoPlaying]);

	useEffect(() => {
		if (isMicPlaying !== undefined) {
			sendMyMicStatus();
		}
	}, [isMicPlaying]);

	const sentMyName = () => {
		const clearDiceResultsMessage = {
			type: CHAT_EVENTS.USER_NAME,
			id: id,
			name: name,
		};
		sendText(clearDiceResultsMessage);
	};

	const sentMyAvatar = () => {
		const avatarMessage = {
			type: CHAT_EVENTS.AVATAR,
			id: id,
			avatar: avatar,
		};
		sendText(avatarMessage);
	};

	const sentMyVideoStatus = () => {
		const videoStatusMessage = {
			type: CHAT_EVENTS.VIDEO_STATUS,
			id: id,
			isLocalVideoPlaying: isLocalVideoPlaying,
		};
		sendText(videoStatusMessage);
	};

	const sendMyMicStatus = () => {
		const micStatusMessage = {
			type: CHAT_EVENTS.MIC_STATUS,
			id: id,
			isMicPlaying: isMicPlaying,
		};
		sendText(micStatusMessage);
	};

	const sentImage = () => {
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_IMAGE,
			img: sharedImage,
		};
		sendText(shareImgMessage);
	};

	const sentAudios = () => {
		console.log('sent shared audios', sharedAudios);
		const shareMusicMessage = {
			type: CHAT_EVENTS.SHARE_S3_AUDIO,
			img: sharedAudios,
		};
		sendText(shareMusicMessage);
	};

	async function gotRemoteStream(event, remoteStream) {
		const tempChat = [...remoteChat];
		if (!tempChat.find((x) => x.id === remoteStream.id)) {
			tempChat.push(remoteStream);
			await setRemoteChat(tempChat);
		}
	}

	const sendText = (message, id) => {
		if (remoteChat.length > 0) {
			remoteChat.map((userChannel) => {
				//TODO: DO not send to remoteChat closed!!!!
				try {
					if (
						(!id || userChannel.id === id) &&
						userChannel.channel.readyState !== 'closed'
					) {
						userChannel.channel.send(JSON.stringify(message));
					}
				} catch (e) {
					console.log('error sending message', e);
				}
			});
		}
		addMessageToChat(message);
	};

	const addMessageToChat = async (message) => {
		const newChatMessage = [...messages, message];

		await setMessages(newChatMessage);
	};

	return {
		messages,
		sendMessage: sendText,
	};
}

export default useChat;
