import React, { useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';

import { useRecoilState, useRecoilValue } from 'recoil';

import {
	CamerasBoard,
	ControlList,
	DiceSelector,
	FileManager,
	ImageUploadContainer,
	UserInfoSelector,
} from './../../components';
import { CHAT_EVENTS } from './../../common/constants';
import { useChat } from './../../common/hooks';
import { diceResultsState, diceRollState, userIdState } from './../../common/recoil/UserInfo/atoms';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	content: {
		width: '100%',
		height: 'calc(100% - 56px)',
	},
	bottomNavigation: {
		width: '100%',
		height: '54px',
	},
}));

const ControlBoard = ({ externalClasses }) => {
	const classes = useStyles();

	const [openDiceSelector, setOpenDiceSelector] = useState(false);
	const [openUserInfoSelector, setOpenUserInfoSelector] = useState(false);
	const id = useRecoilValue(userIdState);
	const [roll, setRoll] = useRecoilState(diceRollState);
	const [diceResults, setDiceResults] = useRecoilState(diceResultsState);
	const { messages, sendMessage } = useChat(id);

	const manageRoll = (value) => {
		if (roll === 0) {
			setRoll(value);
		}
	};

	const clearDiceResults = () => {
		setDiceResults([]);
		const clearDiceResultsMessage = {
			type: CHAT_EVENTS.CLEAR_DICE_RESULTS,
			id: id,
		};
		sendMessage(clearDiceResultsMessage);
	};

	return (
		<Box display="flex" flexDirection="columns" className={classes.root}>
			<Box width="100%" height="90%" className={classes.content}>
				<CamerasBoard />
			</Box>
			<Box width="100%" height="90%" className={classes.content}>
				<ImageUploadContainer />
			</Box>
			<Box width="100%" height="10%" className={classes.bottomNavigation}>
				<ControlList
					isBottomMenu={true}
					clearDiceResults={clearDiceResults}
					setOpenDiceSelector={setOpenDiceSelector}
					setOpenUserInfoSelector={setOpenUserInfoSelector}
					manageRoll={manageRoll}></ControlList>
			</Box>
			<DiceSelector open={openDiceSelector} setOpen={setOpenDiceSelector} />
			<UserInfoSelector open={openUserInfoSelector} setOpen={setOpenUserInfoSelector} />
			<FileManager />
		</Box>
	);
};

export default ControlBoard;
