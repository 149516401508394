import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { localShareActiveState } from './../../common/recoil/Share/atom';
import { isShareActiveSelector } from './../../common/recoil/Share/selectors';
import { diceRollState } from './../../common/recoil/UserInfo/atoms';

import './GameMenu.scss'

const GameMenu = ({
    isChatOpen,
    setIsChatOpen,
    stream,
    streamConnections,
    setStreamConnections,
    showSelector,
    setShowSelector
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [localShareActive, setLocalShareActive] = useRecoilState(localShareActiveState);
    const isShareActive = useRecoilValue(isShareActiveSelector);
    const [roll, setRoll] = useRecoilState(diceRollState);
    const diceValues = [4, 6, 8, 10, 12, 20, 100];
    const { t, i18n } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const manageChat = () => {
        setIsChatOpen(!isChatOpen);
        handleClose();
    }

    const manageDiceSelector = () => {
        setShowSelector(!showSelector);
        handleClose();
    }

    const manageRoll = (value) => {
        setRoll(value);
        handleClose();
    }

    const addStream = () => {
        const tempStreams = [...streamConnections];
        tempStreams.push({
            streamMedia: stream,
            id: tempStreams.length
        });            
        setStreamConnections(tempStreams);
        handleClose();
    }

    return (
        <Box className="GameMenu">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVert></MoreVert>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={manageChat}>{!isChatOpen ? 'Open' : 'Close'} Chat </MenuItem>
                {
                    diceValues.map(value => {
                        return <MenuItem onClick={() => { manageRoll(value) }}> Tira dado de {value} </MenuItem>;
                    })
                }
                {/* <MenuItem onClick={manageDiceSelector}> Elije dados para tirar </MenuItem> */}
                {/* <MenuItem onClick={addStream}>Add local stream</MenuItem> */}
                {
                    !isShareActive && <MenuItem onClick={() => { setLocalShareActive(true) }}>
                        { t('gameMenu.options.share') }
                    </MenuItem>
                }
                <MenuItem >
                    <AmplifySignOut></AmplifySignOut>
                </MenuItem>
            </Menu>
        </Box>
    )
};

export default memo(GameMenu);