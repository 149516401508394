import React from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { SnackbarProvider } from 'notistack';
import { RecoilRoot } from 'recoil';
import WebSocketClient from 'websocket';
import PubSub from 'pubsub-js';

import './App.css';

import { CLIENT_EVENTS, USER_STREAM_STATUS } from './common/constants';
import awsconfig from './common/aws-exports';
import UserStream from './common/services/userStream';
import { Game } from './pages/index';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

Amplify.configure(awsconfig);

function App() {
	// const script = document.createElement("script");

	// script.src = "/mycannon.js";
	// script.async = true;

	// document.body.appendChild(script);

	let client = new WebSocketClient.w3cwebsocket(
		'wss://j0bip9ycyh.execute-api.eu-west-1.amazonaws.com/dev'
	);

	let id = null;

	let roomConnections = [];
	let mediaStreams = [];
	let localMedia = null;

	const addStream = (remoteId, stream) => {
		mediaStreams.push({
			id: remoteId,
			streamMedia: stream,
		});
		var event = new CustomEvent('newStreamMedia', {
			detail: {
				id: remoteId,
				streamMedia: stream,
			},
		});
		document.dispatchEvent(event);
	};

	const addDataChannel = (remoteId, channel) => {
		var event = new CustomEvent('newDataChannel', {
			detail: {
				id: remoteId,
				channel: channel,
			},
		});
		document.dispatchEvent(event);
	};

	const removeDataChannel = (remoteId) => {
		var event = new CustomEvent('removeDataChannel', {
			detail: {
				id: remoteId,
			},
		});
		document.dispatchEvent(event);
	};

	const addNewRemoteMessage = (remoteId, message) => {
		var event = new CustomEvent('newDataChannelMessage', {
			detail: {
				id: remoteId,
				message: message,
			},
		});
		document.dispatchEvent(event);
	};

	const removeStream = (remoteId) => {
		var event = new CustomEvent('removeStream', {
			detail: {
				id: remoteId,
			},
		});
		document.dispatchEvent(event);
	};
	const refetchRoomUsers = (remoteId) => {
		var event = new CustomEvent('refetchRoomUsers', {
			detail: {
				id: remoteId,
			},
		});
		document.dispatchEvent(event);
	};

	const setId = (newId) => {
		id = newId;
	};

	const setLocalMedia = (media) => {
		roomConnections.map((x) => x.updateLocalMedia(media));
		localMedia = media;
	};

	const setRoomConections = (newRoomConnection) => {
		if (localMedia && newRoomConnection.length > 0) {
			const connectionsToPong = roomConnections
				.filter((oldConnection) =>
					newRoomConnection.some((x) => {
						return (
							oldConnection.status === USER_STREAM_STATUS.CREATED &&
							x.id === oldConnection.id
						);
					})
				)
				.forEach((x) => {
					x.pong();
				});

			newRoomConnection
				.filter((x) => !roomConnections.some((oldConnection) => oldConnection.id === x.id))
				.map((newConnection) => {
					const newConnectionStream = new UserStream({
						client: client,
						id: id,
						remoteId: newConnection.id,
						status: newConnection.status,
						initialData: newConnection.initialData,
						addStream: addStream,
						addDataChannel: addDataChannel,
						addNewRemoteMessage: addNewRemoteMessage,
						removeStream: removeStream,
						removeDataChannel: removeDataChannel,
						localMedia: localMedia,
					});
					roomConnections.push(newConnectionStream);
				});
		}
	};

	const retrieveLogs = (ids = []) => {
		return roomConnections
			.filter((x) => ids.length === 0 || ids.includes(x.id))
			.map((x) => {
				return { id: x.id, logs: x.logs };
			});
	};

	const resyncUser = (event, id) => {
		const tempStream = roomConnections.find((x) => {
			return x.id === id;
		});

		tempStream.createStream();
		tempStream.setDataChannel();
		tempStream.resync();
	};

	const deleteUser = (event, id) => {
		const tempStream = roomConnections.find((x) => {
			return x.id === id;
		});

		tempStream.delete();

		roomConnections = roomConnections.filter((x) => {
			return x.id !== id;
		});

		mediaStreams = mediaStreams.filter((x) => {
			return x.id !== id;
		});
		removeStream(id);
		setTimeout(refetchRoomUsers, 5000);
	};

	PubSub.subscribe(CLIENT_EVENTS.RESYNC_USER, resyncUser);
	PubSub.subscribe(CLIENT_EVENTS.DELETE_USER, deleteUser);

	return (
		<RecoilRoot>
			<SnackbarProvider maxSnack={7} preventDuplicate>
				<div className="App">
					{/* <UserStreamsContainer
						client={client}
						roomConnections={roomConnections}
						addClient={addClient}
						id={id}
					/> */}
					<Game
						client={client}
						retrieveLogs={retrieveLogs}
						setId={setId}
						setLocalMedia={setLocalMedia}
						setRoomConections={setRoomConections}
						resyncUser={resyncUser}
					/>
				</div>
			</SnackbarProvider>
		</RecoilRoot>
	);
}

export default withAuthenticator(App);
