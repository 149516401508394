import React from 'react';

import { UserStream } from '../index';

class UserStreamsContainer extends React.Component {
	client = null;

	constructor(props) {
		super(props);
		console.log(this.client);
	}

	componentDidUpdate(prevProps) {
		console.log('detected update', this.props.roomConnections);
	}

	render() {
		const userStreamObjects = this.props.roomConnections.map(
			(connections) => {
				return (
					<UserStream
						id={this.props.id}
						remoteId={connections.id}
						status={connections.status}
						initialData={connections.initialData}
						client={this.props.client}
						addClient={this.props.addClient}></UserStream>
				);
			}
		);
		console.log('userStreamObjects', userStreamObjects);
		return (
			<>
				User stream container has
				{userStreamObjects}
				elements
				{this.props.roomConnections.length}
			</>
		);
	}
}

export default UserStreamsContainer;
