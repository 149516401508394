// imports the React Javascript Library
import React from 'react';
import Axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
//Card
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';

import Icon from '@material-ui/core/Icon';
import PageviewIcon from '@material-ui/icons/Pageview';
import SearchIcon from '@material-ui/icons/Search';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CollectionsIcon from '@material-ui/icons/Collections';

import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

// Search
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';

//Tabs
import { withStyles } from '@material-ui/core/styles';

const imageGallery = [
	'https://raw.githubusercontent.com/dxyang/StyleTransfer/master/style_imgs/mosaic.jpg',
	'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg',
	'https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dora-maar-picasso.jpg',
	'https://pbs.twimg.com/profile_images/925531519858257920/IyYLHp-u_400x400.jpg',
	'https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dog.jpg',
	'http://r.ddmcdn.com/s_f/o_1/cx_462/cy_245/cw_1349/ch_1349/w_720/APL/uploads/2015/06/caturday-shutterstock_149320799.jpg',
];

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	icon: {
		margin: theme.spacing.unit * 2,
	},
	iconHover: {
		margin: theme.spacing.unit * 2,
		'&:hover': {
			color: red[800],
		},
	},
	cardHeader: {
		textalign: 'center',
		align: 'center',
		backgroundColor: 'white',
	},
	input: {
		display: 'none',
	},
	title: {
		color: blue[800],
		fontWeight: 'bold',
		fontFamily: 'Montserrat',
		align: 'center',
	},
	button: {
		color: blue[900],
		margin: 10,
	},
	secondaryButton: {
		color: 'gray',
		margin: 10,
	},
	typography: {
		margin: theme.spacing.unit * 2,
		backgroundColor: 'default',
	},

	searchRoot: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 400,
	},
	searchInput: {
		marginLeft: 8,
		flex: 1,
	},
	searchIconButton: {
		padding: 10,
	},
	searchDivider: {
		width: 1,
		height: 28,
		margin: 4,
	},
});

class ImageUploadCard extends React.Component {
	state = {
		mainState: 'initial', // initial, search, gallery, uploaded
		imageUploaded: 0,
		selectedFile: null,
	};

	uploadImage = async (image) => {
		const currentSession = await Auth.currentSession();

		let parts = image.split(';');
		let mime = parts[0].split(':').slice(1, 2)[0];
		let name = parts[1].split('=').slice(1, 100).join('');
		let data = parts[1];

		const imageToSend = {
			mime: mime,
			name: 'image_2021-07-01_18-58-52.png',
			image: data,
		};
		const config = {
			headers: {
				Accept: '*/*',
				Authorization: currentSession.getIdToken().getJwtToken(),
				'content-type': '',
				'X-API-KEY': 'ee6J8LUPwn9dJhxAqwkrU3R7Xi2UuIb18QjoNs0p',
			},
		};
		const response = await Axios.post(
			'https://h4ckpdm4hg.execute-api.eu-west-1.amazonaws.com/dev/imageUpload',
			imageToSend,
			config
		);
	};

	handleUploadClick = (event) => {
		var file = event.target.files[0];
		const reader = new FileReader();
		var url = reader.readAsDataURL(file);

		reader.onloadend = function (e) {
			this.setState({
				selectedFile: [reader.result],
			});
			this.uploadImage(reader.result);
		}.bind(this);

		this.setState({
			mainState: 'uploaded',
			selectedFile: event.target.files[0],
			imageUploaded: 1,
		});
	};

	handleSearchClick = (event) => {
		this.setState({
			mainState: 'search',
		});
	};

	handleGalleryClick = (event) => {
		this.setState({
			mainState: 'gallery',
		});
	};

	renderInitialState() {
		const { classes, theme } = this.props;
		const { value } = this.state;

		return (
			<React.Fragment>
				<CardContent>
					<Grid container justify="center" alignItems="center">
						<input
							accept="image/*"
							className={classes.input}
							id="contained-button-file"
							multiple
							type="file"
							onChange={this.handleUploadClick}
						/>
						<label htmlFor="contained-button-file">
							<Fab component="span" className={classes.button}>
								<AddPhotoAlternateIcon />
							</Fab>
						</label>
						<Fab className={classes.button} onClick={this.handleSearchClick}>
							<SearchIcon />
						</Fab>
						<Fab className={classes.button} onClick={this.handleGalleryClick}>
							<CollectionsIcon />
						</Fab>
					</Grid>
				</CardContent>
			</React.Fragment>
		);
	}

	handleSearchURL = (event) => {
		var file = event.target.files[0];
		var reader = new FileReader();
		var url = reader.readAsDataURL(file);

		reader.onloadend = function (e) {
			this.setState({
				selectedFile: [reader.result],
			});
		}.bind(this);

		this.setState({
			selectedFile: event.target.files[0],
			imageUploaded: 1,
		});
	};

	handleImageSearch(url) {
		var filename = url.substring(url.lastIndexOf('/') + 1);

		this.setState({
			mainState: 'uploaded',
			imageUploaded: true,
			selectedFile: url,
			fileReader: undefined,
			filename: filename,
		});
	}

	handleSeachClose = (event) => {
		this.setState({
			mainState: 'initial',
		});
	};

	renderSearchState() {
		const { classes } = this.props;

		return (
			<Paper className={classes.searchRoot} elevation={1}>
				<InputBase className={classes.searchInput} placeholder="Image URL" />
				<IconButton
					className={classes.button}
					aria-label="Search"
					onClick={this.handleImageSearch}>
					<SearchIcon />
				</IconButton>
				<Divider className={classes.searchDivider} />
				<IconButton
					color="primary"
					className={classes.secondaryButton}
					aria-label="Close"
					onClick={this.handleSeachClose}>
					<CloseIcon />
				</IconButton>
			</Paper>
		);
	}

	handleAvatarClick(value) {
		var filename = value.url.substring(value.url.lastIndexOf('/') + 1);

		this.setState({
			mainState: 'uploaded',
			imageUploaded: true,
			selectedFile: value.url,
			fileReader: undefined,
			filename: filename,
		});
	}

	renderGalleryState() {
		const { classes } = this.props;
		const listItems = this.props.imageGallery.map((url) => (
			<div
				onClick={(value) => this.handleAvatarClick({ url })}
				style={{
					padding: '5px 5px 5px 5px',
					cursor: 'pointer',
				}}>
				<Avatar src={url} />
			</div>
		));

		/*const listItems = this.props.imageGallery.map(url => (
      <div
        onClick={value => this.handleAvatarClick({ url })}
        style={{
          padding: "5px 5px 5px 5px",
          cursor: "pointer"
        }}
      >
        <Avatar shape="square" size={100} src={url} />
      </div>
    ));*/

		return (
			<React.Fragment>
				<Grid>
					{listItems}
					<IconButton
						color="primary"
						className={classes.secondaryButton}
						aria-label="Close"
						onClick={this.handleSeachClose}>
						<ReplayIcon />
					</IconButton>
				</Grid>
			</React.Fragment>
		);
	}

	renderUploadedState() {
		const { classes, theme } = this.props;

		return (
			<React.Fragment>
				<CardActionArea onClick={this.imageResetHandler}>
					<img width="100%" className={classes.media} src={this.state.selectedFile} />
				</CardActionArea>
			</React.Fragment>
		);
	}

	imageResetHandler = (event) => {
		this.setState({
			mainState: 'initial',
			selectedFile: null,
			imageUploaded: 0,
		});
	};

	render() {
		const { classes, theme } = this.props;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Card className={this.props.cardName}>
						{(this.state.mainState == 'initial' && this.renderInitialState()) ||
							(this.state.mainState == 'search' && this.renderSearchState()) ||
							(this.state.mainState == 'gallery' && this.renderGalleryState()) ||
							(this.state.mainState == 'uploaded' && this.renderUploadedState())}
					</Card>
				</div>
			</React.Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
