import { useState, useEffect } from 'react';

import { useRecoilValue, useRecoilState } from 'recoil';

import {
	localStreamState,
	localShareActiveState,
	musicShareActiveState,
	musicShareStreamState,
	shareStreamState,
} from '../../common/recoil/Share/atom';

function useCamera() {
	const [camera, setCamera] = useRecoilState(localStreamState);
	const [onlyCameraStream, setOnlyCameraStream] = useState();

	const musicShareActive = useRecoilValue(musicShareActiveState);
	const musicShareStream = useRecoilValue(musicShareStreamState);

	const [constraints, setConstraints] = useState({
		video: {
			// 	width: { min: 640, ideal: 1280 },
			// 	height: { min: 400, ideal: 720 },
			aspectRatio: { ideal: 1.3 },
		},
		audio: true,
	});

	function gotDevices(deviceInfos) {
		for (let i = 0; i !== deviceInfos.length; ++i) {
			const deviceInfo = deviceInfos[i];
			const option = document.createElement('option');
			option.value = deviceInfo.deviceId;
			if (deviceInfo.kind === 'audioinput') {
				option.text = deviceInfo.label || 'microphone ';
			} else if (deviceInfo.kind === 'videoinput') {
				option.text = deviceInfo.label || 'camera ';
				console.log('found video', deviceInfo);
			} else {
				console.log('Found another kind of device: ', deviceInfo);
			}
		}
	}

	useEffect(() => {
		const getMediaStream = async () => {
			//navigator.mediaDevices.enumerateDevices().then(gotDevices);

			try {
				const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
				setOnlyCameraStream(mediaStream);
				await setCamera(mediaStream);
			} catch (error) {
				console.error(error);
			}
		};
		getMediaStream();
	}, [constraints]);

	useEffect(() => {
		if (musicShareActive && camera) {
			const tempCamera = camera.clone();
			console.log('tempCamera is', tempCamera);
			console.log('musicShareStram is', musicShareStream);
			// musicShareStream.getTracks().forEach((track) => {
			// 	console.log(track);
			// 	tempCamera.addTrack(track);
			// });
			console.log('tempCamera media tracks', musicShareStream.getAudioTracks());

			setCamera(musicShareStream);
		}
	}, [musicShareStream]);

	useEffect(() => {
		if (camera) {
			console.log('updated camera local media', camera);
			console.log('camera local media tracks', camera.getAudioTracks());
		}
	}, [camera]);

	return camera;
}

export default useCamera;
