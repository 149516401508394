import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ onClose, pageTitle, portalIndex, children, showPortal }) => {
	let newWindow;

	function copyStyles(sourceDoc, targetDoc) {
		Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
			let hasRules = false;
			try {
				hasRules = Boolean(styleSheet.cssRules);
			} catch (e) {
				console.log(e);
			}

			if (hasRules) {
				// for <style> elements
				const newStyleEl = sourceDoc.createElement('style');

				Array.from(styleSheet.cssRules).forEach((cssRule) => {
					// write the text of each rule into the body of the style element
					newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
				});

				targetDoc.head.appendChild(newStyleEl);
			} else if (styleSheet.href) {
				// for <link> elements loading CSS from a URL
				const newLinkEl = sourceDoc.createElement('link');

				newLinkEl.rel = 'stylesheet';
				newLinkEl.href = styleSheet.href;
				targetDoc.head.appendChild(newLinkEl);
			}
		});
	}

	const container = useRef(document.createElement('div'));
	useEffect(() => {
		newWindow = window.open('', 'otro titulo', 'width=1000,height=580');

		newWindow.document.body.appendChild(container.current);

		newWindow.document.title = pageTitle;

		copyStyles(window.document, newWindow.document);

		newWindow.addEventListener('beforeunload', () => onClose(portalIndex));

		return () => {
			newWindow.removeEventListener('beforeunload', onClose);
			newWindow.close();
			onClose(portalIndex);
		};
	}, [pageTitle, portalIndex]);

	useEffect(() => {
		if (!showPortal && newWindow) {
			newWindow.close();
		}
	}, [showPortal]);

	return createPortal(children, container.current);
};

export default Portal;
