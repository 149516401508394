import React from 'react';
import { Box } from '@material-ui/core';

import { DICE_TYPES } from './../../common/constants';
import { getDiceClasses } from './../../common/services/l5rDiceTranslator';

import './DiceResult.scss';

const DiceResult = ({ notation, result, type }) => {
	const renderL5RValues = () => {
		return (
			<Box
				className={`l5r-dice ${getDiceClasses(notation, result)}`}
				src="/images/dices/l5r/success.png"></Box>
		);
	};

	return DICE_TYPES.NORMAL === type ? result : renderL5RValues();
};

export default DiceResult;
