import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { DiceIcons } from './../index';
import { diceRollState, isL5RDiceState } from './../../common/recoil/UserInfo/atoms';
import {
	customDicesState,
	customDiceSelectedState,
	dicesBonusListState,
	dicesSelectedState,
} from './../../common/recoil/GameSystem/gameSystemAtom';
import CustomDicesDrawer from './CustomDicesDrawer';
import robotta from './../../common/gameSystems/customDices/robotta.json';
import './DiceSelector.scss';

const DiceSelector = ({ open, setOpen }) => {
	const { t, i18n } = useTranslation();
	const diceValues = [4, 6, 8, 10, 12, 20];
	const [roll, setRoll] = useRecoilState(diceRollState);
	const [isL5RDice, setIsL5RDice] = useRecoilState(isL5RDiceState);
	const [customDices, setCustomDices] = useRecoilState(customDicesState);
	const [customDiceSelected, setCustomDiceSelected] = useRecoilState(customDiceSelectedState);
	const [diceSelected, setDiceSelected] = useRecoilState(dicesSelectedState);
	const [dicesBonusList, setDicesBonusList] = useRecoilState(dicesBonusListState);

	useEffect(() => {
		setDiceSelected([]);
		setCustomDices([robotta]);
	}, [open]);

	const addDice = (diceValue) => {
		setDiceSelected([diceValue, ...diceSelected]);
	};

	const rollDices = () => {
		setRoll(diceSelected);
		setOpen(false);
	};

	return (
		<Box data-testid="DiceSelectorContainer" id="DiceSelectorContainer">
			<Dialog maxWidth="md" fullWidth={true} open={open} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{t('diceSelector.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('diceSelector.content')}</DialogContentText>
					<Box display="flex" flexDirection="column">
						{customDiceSelected ? (
							<CustomDicesDrawer open={open} />
						) : (
							<>
								<Box>
									{diceValues.map((value) => {
										return (
											(!isL5RDice ||
												(isL5RDice && (value === 6 || value === 12))) && (
												<Button
													key={`dice-${value}`}
													onClick={() => {
														addDice(value);
													}}>
													<DiceIcons dice={value} size="small" />
												</Button>
											)
										);
									})}
								</Box>
								<Box mt={3}>
									{diceSelected.map((x) => {
										return `1d${x} + `;
									})}
								</Box>
							</>
						)}
						<Box display="flex" flexDirection="row" mt={3} alignItems="center">
							<Typography>{t('diceSelector.isl5rDice')}</Typography>
							<Checkbox
								disabled={customDiceSelected}
								checked={isL5RDice}
								onChange={(e) => {
									setIsL5RDice(e.target.checked);
								}}
							/>
						</Box>
						{customDices.map((x) => {
							return (
								<Box display="flex" flexDirection="row" mt={0} alignItems="center">
									<Typography>{x.name}</Typography>
									<Checkbox
										disabled={isL5RDice}
										checked={customDiceSelected === x.name}
										onChange={(e) => {
											setCustomDiceSelected(e.target.checked ? x.name : null);
										}}
									/>
								</Box>
							);
						})}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={rollDices}
						color="primary"
						disabled={diceSelected.length === 0}
						data-testid="rollDiceSelectorButton">
						{t('diceSelector.buttons.roll')}
					</Button>
					<Button
						onClick={() => {
							setDiceSelected([]);
							setDicesBonusList([]);
						}}
						color="primary"
						data-testid="cleanDiceSelectorButton">
						{t('diceSelector.buttons.clean')}
					</Button>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						color="primary"
						data-testid="cancel">
						{t('diceSelector.buttons.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DiceSelector;
