import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';
import diceD4Outlined from '@iconify-icons/mdi/dice-d4-outline';
import diceD6Outlined from '@iconify-icons/mdi/dice-d6-outline';
import diceD8Outlined from '@iconify-icons/mdi/dice-d8-outline';
import diceD10Outlined from '@iconify-icons/mdi/dice-d10-outline';
import diceD12Outlined from '@iconify-icons/mdi/dice-d12-outline';
import diceD20Outlined from '@iconify-icons/mdi/dice-d20-outline';
import diceD4 from '@iconify-icons/mdi/dice-d4';
import diceD6 from '@iconify-icons/mdi/dice-d6';
import diceD8 from '@iconify-icons/mdi/dice-d8';
import diceD10 from '@iconify-icons/mdi/dice-d10';
import diceD12 from '@iconify-icons/mdi/dice-d12';
import diceD20 from '@iconify-icons/mdi/dice-d20';

import { SIZES } from './../../common/constants';

const DiceIcons = ({ dice = 10, outlined = false, size = 'medium' }) => {
	let icon = diceD10Outlined;
	if (outlined) {
		switch (dice) {
			case 4:
				icon = diceD4Outlined;
				break;
			case 6:
				icon = diceD6Outlined;
				break;
			case 8:
				icon = diceD8Outlined;
				break;
			case 10:
				icon = diceD10Outlined;
				break;
			case 12:
				icon = diceD12Outlined;
				break;
			case 20:
				icon = diceD20Outlined;
				break;
		}
	} else {
		switch (dice) {
			case 4:
				icon = diceD4;
				break;
			case 6:
				icon = diceD6;
				break;
			case 8:
				icon = diceD8;
				break;
			case 10:
				icon = diceD10;
				break;
			case 12:
				icon = diceD12;
				break;
			case 20:
				icon = diceD20;
				break;
		}
	}
	return <Icon icon={icon} width={SIZES[size]} height={SIZES[size]} />;
};

export default DiceIcons;
