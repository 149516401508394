import React, { memo, useEffect, useMemo, useState, useCallback } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';

import { DiceIcons } from '../index';
import { diceRollState } from '../../common/recoil/UserInfo/atoms';
import {
	customDicesState,
	customDiceSelectedState,
	dicesBonusListState,
	dicesSelectedState,
} from '../../common/recoil/GameSystem/gameSystemAtom';
import { addDiceBonusSelector } from '../../common/recoil/GameSystem/gameSystemSelectors';
import './DiceSelector.scss';
import { withRouter } from 'react-router-dom';

const CustomDicesDrawer = ({ open, setOpen }) => {
	const { t, i18n } = useTranslation();
	const diceValues = [4, 6, 8, 10, 12, 20];
	const [roll, setRoll] = useRecoilState(diceRollState);
	const [customDices, setCustomDices] = useRecoilState(customDicesState);
	const [customDiceSelected, setCustomDiceSelected] = useRecoilState(customDiceSelectedState);
	const [diceSelected, setDiceSelected] = useRecoilState(dicesSelectedState);
	const [dicesBonusList, setDicesBonusList] = useRecoilState(dicesBonusListState);
	const [diceBonus, addDiceBonus] = useRecoilState(addDiceBonusSelector);
	const [tempDiceAdded, setTempDiceAdded] = useState([]);

	const currentCustomDiceConfig = useMemo(() => {
		return customDiceSelected ? customDices.find((x) => x.name === customDiceSelected) : [];
	}, [customDices, customDiceSelected]);

	const addDice = (diceValue) => {
		setDiceSelected([diceValue, ...diceSelected]);
	};

	const addBonus = (name, operation = '+', value, errorRange) => {
		const newBonus = {
			name,
			operation,
			value,
			errorRange,
		};
		addDiceBonus(newBonus);
		//setDicesBonusList([newBonus, ...dicesBonusList.filter((x) => x.name !== name)]);
	};

	const debounceFn = useCallback(debounce(addBonus, 1000), []);

	const handleBonusChange = (name, operation = '+', value, errorRange = 0) => {
		//debounceFn(name, operation, value);
		addBonus(name, operation, value, errorRange);
	};

	const stringValidatedResult = useMemo(() => {
		let validatedResult = '';

		const tempDicesSelected = [];
		switch (currentCustomDiceConfig.validation.dices) {
			case 'autoselect':
				const dicesValid = currentCustomDiceConfig.input
					.filter((input) => input.type === 'dice')
					.map((input) => {
						const quantity = input.quantity || 1;
						for (let i = 0; i < quantity; i++) {
							tempDicesSelected.push(input.value);
						}
						return `${input.quantity}d${input.value}`;
					});
				validatedResult += dicesValid.join(' + ');
				break;
			default:
				return null;
				break;
		}

		const bonusProperties = currentCustomDiceConfig.input.filter((x) =>
			['textField', 'checkbox'].includes(x.type)
		);

		validatedResult += bonusProperties
			.map((x) => {
				const bonusValue = dicesBonusList.find((bonus) => bonus.name === x.name);
				let isValidBonus = false;
				try {
					if (bonusValue) {
						isValidBonus = eval(`${bonusValue?.value}${x?.condition}`);
					}
				} catch (e) {
					console.log('error validating bonus property', x);
					console.log('error validating bonus bonusValue', bonusValue);
					console.log(`error when validating ${bonusValue?.value}${x?.condition}`, e);
				}
				if (isValidBonus && x.dice) {
					tempDicesSelected.push(x.dice);
				}

				const bonusPlaceholder = currentCustomDiceConfig?.input?.find(
					(x) => x.name === bonusValue?.name
				)?.placeholder || [''];

				const bonusText = bonusPlaceholder[i18n.language] || bonusPlaceholder[0];

				return isValidBonus
					? ` ${bonusValue.operation} ${
							x.showBonusValue ? bonusValue.value || 0 : ''
					  } (${bonusText})`
					: '';
			})
			.join(' ');

		setDiceSelected(tempDicesSelected);

		return validatedResult;
	}, [, tempDiceAdded, dicesBonusList]);

	const DrawInput = memo(
		({ input }) => {
			switch (input.type) {
				case 'dice':
					const quantity = input.quantity || 1;
					const dicesComponents = [];
					for (let i = 0; i < quantity; i++) {
						dicesComponents.push(
							<Button
								key={`dice-${input.value}-i`}
								onClick={() => {
									if (input.action === 'add-dice') {
										addDice(input.value);
									}
								}}>
								<DiceIcons dice={input.value} size="small" />
							</Button>
						);
					}
					return <>{dicesComponents}</>;
					break;
				case 'textField':
					return (
						<Box width="100px">
							<TextField
								ml={1}
								required={input.required}
								key={input.name}
								id={input.name}
								label={input.placeholder[i18n.language] || input.placeholder[0]}
								type={input.textType}
								variant="outlined"
								defaultValue={
									dicesBonusList.find((x) => x.name === input.name)?.value ||
									input.value
								}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(event) => {
									handleBonusChange(
										input.name,
										input.operation,
										event.target.value,
										input.errorRange
									);
								}}
								onFocus={(e) => {
									e.target.select();
								}}
							/>
						</Box>
					);
					break;
				case 'checkbox':
					return (
						<Box width="100px">
							<FormControlLabel
								key={input.name}
								id={input.name}
								control={
									<Checkbox
										color="primary"
										checked={
											dicesBonusList.find((x) => x.name === input.name)
												?.value || false
										}
										name={input.name}
									/>
								}
								label={input.placeholder[i18n.language] || input.placeholder[0]}
								labelPlacement="top"
								onChange={(event) => {
									handleBonusChange(
										input.name,
										input.operation,
										event.target.checked
									);
								}}
							/>
						</Box>
					);
					break;
				default:
					return null;
					break;
			}
		},
		[dicesBonusList]
	);

	const drawConfigElements = useMemo(() => {
		return currentCustomDiceConfig.input.map((input) => {
			return <DrawInput input={input} />;
		});
	}, [currentCustomDiceConfig, dicesBonusList]);

	return (
		<Box data-testid="CustomDicesDrawer" id="CustomDicesDrawer">
			<Box display="flex">{drawConfigElements}</Box>
			<Box>{stringValidatedResult}</Box>
		</Box>
	);
};

export default CustomDicesDrawer;
