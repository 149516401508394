import { atom } from 'recoil';

export const remoteDiceResultsState = atom({
	key: 'remoteDiceResults',
	default: [],
});

export const remoteChatState = atom({
	key: 'remoteChatState',
	default: [],
});

export const chatmessagesState = atom({
	key: 'chatmessagesState',
	default: [],
});

export const isChatOpenState = atom({
	key: 'isChatOpenState',
	default: false,
});

export const currentChatUserState = atom({
	key: 'currentChatUserState',
	default: 'all',
});
