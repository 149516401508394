import React, { memo, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';

import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useRecoilValue, useRecoilState } from 'recoil';
import { SnackbarProvider, useSnackbar } from 'notistack';

import {
	AudioTooltip,
	ControlDrawer,
	LogViewer,
	ImageUploadCard,
	Tabletop,
	UserInfo,
	UserStream,
} from './../../components/index';
import { FAKE_STREAM, IS_LOCALHOST, CHAT_EVENTS } from './../../common/constants';
import { getUserLibraries } from '../../common/services/libraryRequests';
import {
	chatMessagesSelector,
	remoteChatSelector,
	removeChatChannelState,
} from './../../common/recoil/chatMessages/selectors';
import { remoteChatState } from './../../common/recoil/chatMessages/atoms';
import {
	publicLibrariesState,
	privateLibrariesState,
	rulesLibraryState,
} from './../../common/recoil/Library/libraryAtom';
import { localStreamState, sharedAudiosState } from './../../common/recoil/Share/atom';
import { userIdState, userNameState } from './../../common/recoil/UserInfo/atoms';
import {
	remoteNamesState,
	remoteMediaStreamsState,
	roomConnectionsState,
} from './../../common/recoil/websocket/atoms';
import {
	remoteMediaStreamsSelector,
	removeMediaStreamsSelector,
} from './../../common/recoil/websocket/selectors';

const Game = ({ client, retrieveLogs, setId, setLocalMedia, setRoomConections }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const id = useRecoilValue(userIdState);
	const userName = useRecoilValue(userNameState);
	const localMedia = useRecoilValue(localStreamState);
	const sharedAudios = useRecoilValue(sharedAudiosState);

	const [roomConnections, setRoomConnections] = useRecoilState(roomConnectionsState);
	const [remoteMediaStream, setRemoteMediaStream] = useRecoilState(remoteMediaStreamsState);
	const [remoteMediaStreamsGetSelector, setRemoteMediaStreamsSelector] = useRecoilState(
		remoteMediaStreamsSelector
	);
	const [remoteNames, setRemoteNames] = useRecoilState(remoteNamesState);
	const [removeMediaStreams, setRemoveMediaStreams] = useRecoilState(removeMediaStreamsSelector);
	const [remoteChat, setRemoteChat] = useRecoilState(remoteChatState);
	const [remoteChatS, addRemoteChat] = useRecoilState(remoteChatSelector);
	const [removeChatChannel, setRemoveChatChannel] = useRecoilState(removeChatChannelState);
	const [publicLibraries, setPublicLibraries] = useRecoilState(publicLibrariesState);
	const [privateLibraries, setPrivateLibraries] = useRecoilState(privateLibrariesState);
	const [rulesLibrary, setRulesLibrary] = useRecoilState(rulesLibraryState);
	const [chatMessages, setChatMessagesSelector] = useRecoilState(chatMessagesSelector);

	const removeStreamMedia = (id) => {
		const tempRemoteMediaStream = [...remoteMediaStream];
		setRemoteMediaStream(tempRemoteMediaStream.filter((x) => x.id !== id));
	};

	const removeDataChannel = (id) => {
		const tempRemoteChat = [...remoteChat];
		//setRemoteChat(tempRemoteChat.filter((x) => x.id !== id));
	};

	const useDrawerTableTop = false;

	// (async () => {
	// 	const currentSession = await Auth.currentSession();
	// 	console.log('idToken', currentSession.getIdToken().getJwtToken());
	// 	console.log('currentSession', currentSession);

	// 	const currentCredentials = await Auth.currentCredentials();
	// 	console.log('currentCredentials', currentCredentials);

	// 	const currentUserInfo = await Auth.currentUserInfo();
	// 	console.log('currentUserInfo', currentUserInfo);
	// })();

	useEffect(() => {
		document.addEventListener('newStreamMedia', function (e) {
			setRemoteMediaStreamsSelector({
				id: e.detail.id,
				streamMedia: e.detail.streamMedia,
				name: '',
			});
		});

		document.addEventListener('newDataChannel', function (e) {
			addRemoteChat({ ...e.detail });
		});

		document.addEventListener('newDataChannelMessage', function (e) {
			setChatMessagesSelector(e.detail.message);
		});

		document.addEventListener('removeStream', function (e) {
			setRemoveMediaStreams(e.detail.id);
			// let tempRoomClients = [];
			// console.log('tempROom Clients start', [...tempRoomClients]);
			// tempRoomClients = tempRoomClients.filter((x) => {
			// 	console.log('tempROom ', x);

			// 	x.id !== e.detail.id;
			// });
			// console.log('tempROom Clients end', [...tempRoomClients]);

			// setRoomConnections([...tempRoomClients]);
		});

		retrieveLibraries();
	}, []);

	const addFakeStream = () => {
		console.log('current remoteMediaStreams', remoteMediaStream);
		const name = `REMOTE-${remoteMediaStream.length}`;
		setRemoteMediaStreamsSelector({
			id: name,
			streamMedia: localMedia,
			name: name,
		});
		const newName = {
			id: name,
			name: name,
		};
		setRemoteNames([newName, ...remoteNames]);
	};

	useEffect(() => {
		if (remoteNames.length < 4 && IS_LOCALHOST && localMedia) {
			addFakeStream();
		}
	}, [remoteNames, localMedia]);

	useEffect(() => {
		if (id) {
			setId(id);
		}
	}, [id]);

	useEffect(() => {
		if (localMedia) {
			setLocalMedia(localMedia);
			setRoomConections(roomConnections);
		}
	}, [localMedia]);

	useEffect(() => {
		if (roomConnections) {
			setRoomConections(roomConnections);
		}
	}, [roomConnections]);

	useEffect(() => {
		document.removeEventListener('removeDataChannel', function (e) {
			removeDataChannel(e.detail.id);
		});
		document.addEventListener('removeDataChannel', function (e) {
			removeDataChannel(e.detail.id);
		});
	}, [remoteChat]);

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	function warnAboutMessage(data) {
		try {
			const message = JSON.parse(data);
			if (message?.type === CHAT_EVENTS.CHAT_WARNING) {
				enqueueSnackbar(message?.text, {
					variant: 'warning',
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					action: (key) => (
						<Button onClick={onClickDismiss(key)}>
							<Close color={'white'}></Close>
						</Button>
					),
					preventDuplicate: true,
				});
			}
		} catch (e) {
			console.log('error parsing remote message');
		}
	}

	useEffect(() => {
		if (remoteChat) {
			remoteChat.map((x) => {
				if (!x.channel.onmessage) {
					x.channel.onmessage = (event) => {
						warnAboutMessage(event.data);

						setChatMessagesSelector(event.data);
					};
				}
			});
		}
	}, [remoteChat]);

	const retrieveLibraries = async () => {
		const libraries = await getUserLibraries();
		setPublicLibraries(libraries.public);
		setPrivateLibraries(libraries.private);
		setRulesLibrary(libraries?.rules || []);
	};

	return (
		<div>
			<>
				{userName === '' && <UserInfo></UserInfo>}
				{useDrawerTableTop ? (
					<Tabletop client={client}></Tabletop>
				) : (
					<ControlDrawer client={client}></ControlDrawer>
				)}
			</>
			<LogViewer retrieveLogs={retrieveLogs} />
			<Box visibility="hidden">
				<FormControl component="fieldset">
					<FormLabel component="legend"></FormLabel>
					<RadioGroup aria-label="" name="">
						<FormControlLabel control={<Radio />} />
						<FormControlLabel control={<Checkbox />} />
					</RadioGroup>
				</FormControl>
				<Button></Button>
				<ImageUploadCard cardName="Input Image"></ImageUploadCard>
			</Box>
		</div>
	);
};

export default memo(Game, () => false);
