import React, { memo, useEffect, useRef, useState } from 'react';
import PubSub from 'pubsub-js';
import { makeStyles, Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons/';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { CLIENT_EVENTS } from '../../common/constants';

const useStyles = makeStyles(() => ({
	container: {
		position: 'absolute',
		right: 0,
		top: 0,
		zIndex: 1,
	},
	inlineContainer: {
		position: 'absolute',
		zIndex: 1,
	},
	inlineStreamButtons: {
		background: 'black',
		color: 'white',
		padding: '0',
		margin: '5px',
	},
}));

const StreamMenu = ({ userId, overridenTop, overridenRight }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const { t, i18n } = useTranslation();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const removeUser = () => {
		PubSub.publish(CLIENT_EVENTS.DELETE_USER, userId);
		handleClose();
	};

	const resyncUser = () => {
		PubSub.publish(CLIENT_EVENTS.RESYNC_USER, userId);
		handleClose();
	};

	const options = [
		{
			title: t('streamMenu.remove'),
			action: removeUser,
		},
		{
			title: t('streamMenu.resync'),
			action: resyncUser,
		},
	];

	return (
		<Box className={classes.inlineContainer} top={overridenTop} right={overridenRight}>
			<IconButton
				className={classes.inlineStreamButtons}
				color="primary"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}>
				<MoreVert />
			</IconButton>
			<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
				{options.map((option) => (
					<MenuItem key={option.title} onClick={option.action}>
						{option.title}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};

export default StreamMenu;
