import { useState, useEffect } from 'react';

function useSocketNames(socket, connections, myName) {
	const [userNames, setUserNames] = useState([]);

	useEffect(() => {
		connections.map((connection) => {
			if (!userNames[connection.userId]) {
				requestUserName(connection.userId);
			}
		});
	}, [connections]);

	useEffect(() => {
		if (socket) {
			listenSocket();
		}
	}, [socket]);

	const listenSocket = () => {
		socket.on('identity', (data) => {
			if (data.type === 'request') {
				socket.emit('identity', {
					type: 'response',
					toId: data.fromId,
					name: myName,
				});
			} else if (data.type === 'response') {
				setUserName(data.fromId, data.name);
			}
		});
	};

	const requestUserName = (userId) => {
		socket.emit('identity', {
			type: 'request',
			toId: userId,
		});
	};

	const setUserName = (userId, name) => {
		const user = {
			name,
			userId,
		};
		userNames.push(user);
		setUserNames([...userNames]);
	};

	return userNames;
}

export default useSocketNames;
