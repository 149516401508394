import { useState, useEffect } from 'react';
import PubSub from 'pubsub-js';
import { useRecoilState } from 'recoil';

import { CLIENT_EVENTS } from './../constants';
import { roomClientsState, roomConnectionsState } from './../recoil/websocket/atoms';
import { userIdState } from './../recoil/UserInfo/atoms';

function useClientMessages(client) {
	const [roomConnections, setRoomConnections] = useRecoilState(roomConnectionsState);
	const [roomClients, setRoomClients] = useRecoilState(roomClientsState);
	const [id, setId] = useRecoilState(userIdState);

	useEffect(() => {
		if (client) {
			manageMessages();
		}
	}, []);

	const manageMessages = () => {
		client.onmessage = (message) => {
			const parsedMessage = JSON.parse(message.data);
			if (parsedMessage.event === 'connected') {
				setId(parsedMessage.id);
			} else if (parsedMessage.event === 'room-update') {
				setId(parsedMessage.id);
				//setRoomConnections(parsedMessage.clients);
				const clientsButMe = parsedMessage.clients.filter((x) => x !== parsedMessage.id);
				//addClients(parsedMessage.clients);
				addClients(clientsButMe);
				PubSub.publish(CLIENT_EVENTS.ROOM_UPDATE, parsedMessage);
			} else if (parsedMessage.action === 'signaling') {
				if (parsedMessage.type === 'ping') {
					if (!roomConnections.includes(parsedMessage.fromId)) {
						addClients([parsedMessage.fromId], 'active', parsedMessage);
					} else {
						console.log('this user already exists');
					}
				} else {
					PubSub.publish(CLIENT_EVENTS.SIGNALING, parsedMessage);
				}
			}
		};
	};

	const addClients = (clients, status = 'pasive', data = undefined) => {
		const tempRoomClients = [...roomClients];
		clients
			.filter((client) => !tempRoomClients.some((x) => x.id === client))
			.map((client) => {
				tempRoomClients.push({
					id: client,
					status: status,
					initialData: data,
				});
			});
		setRoomConnections(tempRoomClients);
	};

	return id;
}

export default useClientMessages;
