"use strict";
import dice from './dice';

export const diceInit = (container, setResult, diceNotation) => {
    $t.remove($t.id('loading_text'));

    var canvas = container
    canvas.style.width = window.innerWidth - 1 + 'px';
    canvas.style.height = window.innerHeight - 1 + 'px';
    var label = $t.id('label');
    var set = $t.id('set');
    var selector_div = $t.id('selector_div');
    var info_div = $t.id('info_div');
    on_set_change();

    dice.use_true_random = false;

    function on_set_change(ev) { set.style.width = set.value.length + 3 + 'ex'; }
    $t.bind(set, 'keyup', on_set_change);
    $t.bind(set, 'mousedown', function(ev) { ev.stopPropagation(); });
    $t.bind(set, 'mouseup', function(ev) { ev.stopPropagation(); });
    $t.bind(set, 'focus', function(ev) { $t.set(container, { class: '' }); });
    $t.bind(set, 'blur', function(ev) { $t.set(container, { class: 'noselect' }); });

    $t.bind($t.id('clear'), ['mouseup', 'touchend'], function(ev) {
        ev.stopPropagation();
        set.value = '0';
        on_set_change();
    });

    //var params = $t.get_url_params();
    var params = {};

    if (params.chromakey) {
        //dice.desk_color = 0x00ff00;
        info_div.style.display = 'none';
        $t.id('control_panel').style.display = 'none';
    }
    if (params.shadows == 0) {
        dice.use_shadows = false;
    }
    if (params.color == 'white') {
        dice.dice_color = '#808080';
        dice.label_color = '#202020';
    }
    var box = new dice();
    box.dice_box(canvas, { w: 500, h: 300 });
    box.animate_selector = false;

    $t.bind(window, 'resize', function() {
        canvas.style.width = window.innerWidth - 1 + 'px';
        canvas.style.height = window.innerHeight - 1 + 'px';
        box.reinit(canvas, { w: 500, h: 300 });
    });

    function show_selector() {
        info_div.style.display = 'none';
        selector_div.style.display = 'inline-block';
        //box.draw_selector();
    }

    function before_roll(vectors, notation, callback) {
        info_div.style.display = 'none';
        selector_div.style.display = 'none';
        // do here rpc call or whatever to get your own result of throw.
        // then callback with array of your result, example:
        // callback([2, 2, 2, 2]); // for 4d6 where all dice values are 2.
        callback();
    }

    function notation_getter() {
        //return box.parse_notation(set.value);
        return box.parse_notation(diceNotation);
    }

    function after_roll(notation, result) {
        if (params.chromakey || params.noresult) return;
        var res = result.join(' ');
        if (notation.constant) {
            if (notation.constant > 0) res += ' +' + notation.constant;
            else res += ' -' + Math.abs(notation.constant);
        }
        if (result.length > 1) res += ' = ' + 
                (result.reduce(function(s, a) { return s + a; }) + notation.constant);
        label.innerHTML = res;
        info_div.style.display = 'inline-block';
        setResult(result);
    }

    box.bind_mouse(container, notation_getter, before_roll, after_roll);
    box.bind_throw($t.id('throw'), notation_getter, before_roll, after_roll);

    $t.bind(container, ['mouseup', 'touchend'], function(ev) {
        ev.stopPropagation();
        if (selector_div.style.display == 'none') {
            if (!box.rolling) show_selector();
            box.rolling = false;
            return;
        }
        var name = box.search_dice_by_mouse(ev);
        if (name != undefined) {
            //var notation = box.parse_notation(set.value);
            var notation = box.parse_notation(diceNotation);
            notation.set.push(name);
            set.value = box.stringify_notation(notation);
            on_set_change();
        }
    });

    if (params.notation) {
        set.value = params.notation;
    }
    if (params.roll) {
        $t.raise_event($t.id('throw'), 'mouseup');
    }
    else {
        show_selector();
    }
}

export const rollDice = () => {
    $t.raise_event($t.id('throw'), 'mouseup');
};

export const drawDice = (canvas) => {
    var box = new dice();
    box.dice_box(canvas, { w: 200, h: 200 });
    var vectors = box.generate_vectors({ set: ['d4'] }, { x: 0, y: 0, z: 0 }, 0);
    //box.create_dice('d4', {x: 0, y: 0, z: 0}, {x: 0, y: 0, z: 0}, {x: 0, y: 0, z: 0}, {x: 0, y: 0, z: 0, a: 0} );
    //box.roll(vectors, [1], (a) => { console.log('called callback', a);})
    box.draw_dice();
};


const $t = {
    bind : function(sel, eventname, func, bubble) {
        if (!sel) return;
        if (eventname.constructor === Array) {
            for (var i in eventname)
                sel.addEventListener(eventname[i], func, bubble ? bubble : false);
        }
        else
            sel.addEventListener(eventname, func, bubble ? bubble : false);
    },
    copyto : function(obj, res) {
        if (obj == null || typeof obj !== 'object') return obj;
        if (obj instanceof Array) {
            for (var i = obj.length - 1; i >= 0; --i)
                res[i] = $t.copy(obj[i]);
        }
        else {
            for (var i in obj) {
                if (obj.hasOwnProperty(i))
                    res[i] = $t.copy(obj[i]);
            }
        }
        return res;
    },
    copy : function(obj) {
        if (!obj) return obj;
        return $t.copyto(obj, new obj.constructor());
    },
    get_mouse_coords : function(ev) {
        var touches = ev.changedTouches;
        if (touches) return { x: touches[0].clientX, y: touches[0].clientY };
        return { x: ev.clientX, y: ev.clientY };
    },
    id : function(id) {
        return document.getElementById(id);
    },
    raise_event : function(sel, eventname, bubble, cancelable) {
        var evt = document.createEvent('UIEvents');
        evt.initEvent(eventname, bubble == undefined ? true : bubble,
                cancelable == undefined ? true : cancelable);
        sel.dispatchEvent(evt);
    },
    remove : function(sel) {
        if (sel) {
            if (sel.parentNode) sel.parentNode.removeChild(sel);
            else for (var i = sel.length - 1; i >= 0; --i)
                sel[i].parentNode.removeChild(sel[i]);
        }
    },
    set : function(sel, props) {
        for (var i in props) sel.setAttribute(i, props[i]);
        return sel;
    }
}
