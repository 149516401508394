import React, { memo, useEffect, useMemo, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
	makeStyles,
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	Input,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { ArrowBack, Close, Group, ExpandLess, ExpandMore, FolderOpen } from '@material-ui/icons';

import { CHAT_EVENTS } from './../../common/constants';
import { useChat } from './../../common/hooks';
import { currentChatUserState } from '../../common/recoil/chatMessages/atoms';
import { userNameState, userAvatarState } from './../../common/recoil/UserInfo/atoms';
import {
	remoteAvatarsState,
	remoteMediaStreamsState,
	remoteNamesState,
} from '../../common/recoil/websocket/atoms';

import './Chat.scss';

const chip = {
	borderRadius: '10px',
	minWidth: '50px',
	textAlign: 'left',
	padding: '1px 10px',
	display: 'flex',
	flexDirection: 'column',
	alignContent: 'center',
	justifyContent: 'start',
	margin: '0 5px',
};
const useStyles = makeStyles((theme) => ({
	minimized: {
		right: '0',
		width: '300px',
		bottom: '0',
		height: '70px',
		position: 'absolute',
		background: 'black',
		cursor: 'pointer',
		display: 'flex',
		color: 'white',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 20px',
		opacity: '0.5',
		'&:hover': {
			opacity: '1',
		},
	},
	cardHidden: {
		position: 'relative',
		zIndex: '-1',
	},
	root: {
		width: 300,
		padding: 0,
		position: 'absolute',
		right: 0,
		bottom: 0,
		cursor: 'move',
	},
	header: {
		background: 'black',
	},
	headerContent: {
		display: 'flex',
		justifyContent: 'space-between',
		cursor: 'move',
		alignItems: 'center',
		color: 'white',
	},
	headerButton: {
		color: 'white',
	},
	cardContent: {
		height: '300px',
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
	userList: {
		cursor: 'pointer',
	},
	messages: {
		display: 'flex',
		flexDirection: 'column',
	},
	othersMessage: {
		float: 'left',
		display: 'flex',
		alignItems: 'end',
		margin: '5px 0',
	},
	myMessage: {
		float: 'right',
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'end',
		margin: '5px 0',
	},
	othersChip: {
		...chip,
		background: theme.palette.info.light,
	},
	myChip: {
		...chip,
		background: theme.palette.success.light,
	},
	chipText: {
		margin: 0,
	},
}));

const Chat = ({ diceResults, id, isOpen, setIsOpen }) => {
	const endMessagesRef = useRef();
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const [inputText, setInputText] = useState('');
	const [seenMessages, setSeenMessages] = useState([]);
	const [currentChatUser, setCurrentChatUser] = useRecoilState(currentChatUserState);
	const { messages, sendMessage } = useChat(id);
	const remoteNames = useRecoilValue(remoteNamesState);
	const userName = useRecoilValue(userNameState);
	const userAvatar = useRecoilValue(userAvatarState);
	const remoteAvatars = useRecoilValue(remoteAvatarsState);
	const streamConnections = useRecoilValue(remoteMediaStreamsState);

	useEffect(() => {
		if (diceResults.length > 0) {
			const diceResultMessage = {
				type: CHAT_EVENTS.DICE_RESULT,
				id: id,
				value: diceResults,
			};
			sendMessage(diceResultMessage);
		}
	}, [diceResults]);

	const currentSelectedUserName = useMemo(() => {
		if (currentChatUser === 'all') {
			return (
				<>
					<Avatar>
						<Group />
					</Avatar>
					<Typography>{t('chat.chatContent.allTitle')}</Typography>
				</>
			);
		} else {
			return (
				<>
					<Avatar src={remoteAvatars.find((x) => x.id === currentChatUser)?.avatarUrl} />
					<Typography>
						{remoteNames.find((x) => x.id === currentChatUser)?.name || currentChatUser}
					</Typography>
				</>
			);
		}
	}, [currentChatUser]);

	const addMessageSeen = (id) => {
		if (!seenMessages.includes(id)) {
			setSeenMessages([id, ...seenMessages]);
		}
	};

	const getNewChatsCount = (user) => {
		if (user === 'all') {
			const newMessages = messages.filter(
				(x) =>
					x?.type === CHAT_EVENTS.CHAT &&
					!x.secretMessage &&
					!seenMessages.includes(x.messageId)
			);
			return newMessages.length;
		} else if (user) {
			const newMessages = messages.filter(
				(x) =>
					x?.type === CHAT_EVENTS.CHAT &&
					x.id === user &&
					x.secretMessage &&
					!seenMessages.includes(x.messageId)
			);
			return newMessages.length;
		} else {
			const chatMessages = messages.filter(
				(x) => x?.type === CHAT_EVENTS.CHAT && !seenMessages.includes(x.messageId)
			);
			return chatMessages.length;
		}
	};

	const sendNewChat = (text) => {
		const destination = currentChatUser === 'all' ? null : currentChatUser;
		const chatMessage = {
			messageId: uuidv4(),
			type: CHAT_EVENTS.CHAT,
			id: id,
			value: text,
			to: destination,
			secretMessage: currentChatUser !== 'all',
		};

		if (destination) {
			warnOtherUsersAboutChat(destination);
		}

		sendMessage(chatMessage, destination);
	};

	const warnOtherUsersAboutChat = (userId) => {
		const destinationName = remoteNames.find((x) => x.id === userId)?.name;
		const chatMessage = {
			type: CHAT_EVENTS.CHAT_WARNING,
			text: t('chat.warningSecretChat', { from: userName, to: destinationName }),
		};

		sendMessage(chatMessage);
	};

	const renderUsers = () => {
		return (
			<List className="users">
				<ListItem
					className={classes.userList}
					key="all"
					onClick={() => {
						setCurrentChatUser('all');
					}}>
					<ListItemAvatar>
						<Avatar>
							<Group />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Badge badgeContent={getNewChatsCount('all')} color="secondary">
								{t('chat.chatContent.allTitle')}
							</Badge>
						}
					/>
				</ListItem>

				{remoteNames.map((user) => {
					return (
						<>
							<Divider key={`divider-${user.id}`} variant="inset" component="li" />
							<ListItem
								className={classes.userList}
								key={`user-${user.id}`}
								onClick={() => {
									setCurrentChatUser(user.id);
								}}>
								<ListItemAvatar>
									<Avatar
										src={remoteAvatars.find((x) => x.id === user.id)?.avatarUrl}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={
										<Badge
											badgeContent={getNewChatsCount(user.id)}
											color="secondary">
											{user.name}
										</Badge>
									}
								/>
							</ListItem>
						</>
					);
				})}
			</List>
		);
	};

	const renderMessages = () => {
		return (
			<Box className={classes.messages}>
				{getMessagesList()}
				<Box ref={endMessagesRef}></Box>
			</Box>
		);
	};

	const scrollToBottom = () => {
		if (endMessagesRef.current) endMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(scrollToBottom, [messages]);

	const getMessagesList = () => {
		let hasShownNewLine = false;
		return messages
			.filter(
				(x) =>
					x?.type === CHAT_EVENTS.CHAT &&
					isOpen &&
					((currentChatUser === 'all' && !x.secretMessage) ||
						((x.id === currentChatUser || x.to === currentChatUser) && x.secretMessage))
			)
			.map((message, i, j) => {
				const isFirstUserMessage = i === 0 || j[i - 1].id !== message.id;
				const shouldShowNewLine =
					!hasShownNewLine && !seenMessages.includes(message.messageId) && i !== 0;
				if (shouldShowNewLine) {
					hasShownNewLine = true;
				}
				addMessageSeen(message.messageId);
				return (
					<>
						{shouldShowNewLine && (
							<Box id="encuentrameleche" key={'nuevo'}>
								Holaaaa
							</Box>
						)}
						<Box
							key={message.messageId}
							className={
								message.id === id ? classes.myMessage : classes.othersMessage
							}>
							{isFirstUserMessage && (
								<Avatar
									src={
										message.id === id
											? userAvatar?.url
											: remoteAvatars.find((x) => x.id === message.id)
													?.avatarUrl
									}
								/>
							)}

							<Box
								className={message.id === id ? classes.myChip : classes.othersChip}>
								{isFirstUserMessage && (
									<Typography
										className={classes.chipText}
										variant="caption"
										display="block"
										gutterBottom>
										{remoteNames.find((x) => x.id === message.id)?.name}
									</Typography>
								)}

								<Typography
									className={classes.chipText}
									variant="caption"
									display="block"
									gutterBottom>
									{message.value}
								</Typography>
							</Box>
						</Box>
					</>
				);
			});
	};

	const renderChatInput = () => {
		return (
			<>
				<Input
					inputRef={(input) => input && input.focus()}
					value={inputText}
					placeholder={t('chat.chatContent.placeholder')}
					onKeyUp={(event) => {
						if (event.key === 'Enter' && inputText !== '') {
							sendNewChat(inputText);
							setInputText('');
						}
					}}
					onChange={(event, data) => {
						setInputText(event.currentTarget.value);
					}}></Input>
				<Button
					disabled={inputText === ''}
					onClick={() => {
						sendNewChat(inputText);
						setInputText('');
					}}>
					{t('chat.chatContent.send')}
				</Button>
			</>
		);
	};

	const Header = () => {
		return (
			<Box className={classes.headerContent}>
				{currentChatUser ? (
					<>
						<Button
							className={classes.headerButton}
							onClick={() => {
								setCurrentChatUser();
							}}>
							<Badge badgeContent={getNewChatsCount()} color="secondary">
								<ArrowBack color={'white'}></ArrowBack>
							</Badge>
						</Button>
						{currentSelectedUserName}
					</>
				) : (
					<Typography>Chat</Typography>
				)}

				<Button
					className={classes.headerButton}
					onClick={() => {
						setIsOpen(false);
					}}>
					<ExpandMore color={'white'}></ExpandMore>
				</Button>
			</Box>
		);
	};

	return (
		<>
			<Draggable bounds="body">
				<Card className={isOpen ? classes.root : classes.cardHidden} variant="outlined">
					<CardHeader className={classes.header} title={<Header />} />
					<CardContent className={classes.cardContent}>
						{currentChatUser ? renderMessages() : renderUsers()}
					</CardContent>
					<CardActions>{currentChatUser ? renderChatInput() : null}</CardActions>
				</Card>
			</Draggable>
			{!isOpen && (
				<Box
					className={classes.minimized}
					onClick={() => {
						setIsOpen(true);
					}}>
					<Badge badgeContent={getNewChatsCount()} color="secondary">
						<Typography>{t('chat.minimizedChat.title')}</Typography>
					</Badge>
					<ExpandLess color={'white'}></ExpandLess>
				</Box>
			)}
		</>
	);
};

export default memo(Chat);
