import { atom } from 'recoil';

export const showLibrariesManagerState = atom({
	key: 'showLibrariesManager',
	default: false,
});

export const uploadingLibraryFileState = atom({
	key: 'uploadingLibraryFile',
	default: false,
});

export const currentLibraryTypeState = atom({
	key: 'currentLibraryType',
	default: 0,
});

export const currentLibraryBreadCrumbState = atom({
	key: 'currentLibraryBreadCrumb',
	default: [],
});

export const showCreateLibraryDialogState = atom({
	key: 'showCreateLibraryDialog',
	default: false,
});

export const showCreateFolderDialogState = atom({
	key: 'showCreateFolderDialog',
	default: false,
});

export const publicLibrariesState = atom({
	key: 'publicLibraries',
	default: [],
});

export const privateLibrariesState = atom({
	key: 'privateLibraries',
	default: [],
});

export const rulesLibraryState = atom({
	key: 'rulesLibraryState',
	default: [],
});

export const publicLibrariesContentState = atom({
	key: 'publicLibrariesContent',
	default: [],
});

export const privateLibrariesContentState = atom({
	key: 'privateLibrariesContent',
	default: [],
});

export const rulesLibraryContentState = atom({
	key: 'rulesLibraryContentState',
	default: [],
});

export const forceLibraryUpdate = atom({
	key: 'forceLibraryUpdate',
	default: 0,
});
