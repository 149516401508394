import React from 'react';
import { Box } from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import { currentCameraLayoutState } from './../../common/recoil/Cameras/atom';
import HighlightedCameras from './components/HighlightedCameras';

const CameraLayout = ({ localMedia }) => {
	const currentCameraLayout = useRecoilValue(currentCameraLayoutState);

	return (
		<Box>
			<HighlightedCameras localMedia={localMedia} />
		</Box>
	);
};

export default CameraLayout;
