import React from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({
	open,
	setOpen,
	title,
	text,
	successButtonText,
	cancelButtonText,
	onSuccess,
	onCancel,
}) => {
	return (
		<Box data-testid="ConfirmDialog" id="ConfirmDialog">
			<Dialog open={open} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{text}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onSuccess}
						color="primary"
						data-testid="confirm-dialog-success">
						{successButtonText}
					</Button>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						color="primary"
						data-testid="confirm-dialog-cancel">
						{cancelButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ConfirmDialog;
