import React, { useCallback, useState } from 'react';
import { grey } from '@material-ui/core/colors/';
import {
	makeStyles,
	AppBar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Divider,
	Drawer,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Toolbar,
	Typography,
} from '@material-ui/core';
import { ArrowBack, Close, InsertDriveFile, StopScreenShare } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { Auth } from 'aws-amplify';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
	shareCanvasImgState,
	sharedAudiosState,
	sharedImageState,
	uploadedImagesState,
} from '../../../common/recoil/Share/atom';
import { addNewFile } from '../../../common/recoil/Share/selectors';
import { currentLibraryBreadCrumbState } from '../../../common/recoil/Library/libraryAtom';
import { CHAT_EVENTS } from '../../../common/constants';
import { useChat } from '../../../common/hooks';
import { uploadFile } from '../../../common/services/libraryFilesRequests';
import LibraryFolders from './LibraryFolders';
import LibraryFiles from './LibraryFiles';
import LibraryDropZone from './LibraryDropZone';

const useStyles = makeStyles((theme) => ({
	folderTree: {
		backgroundColor: grey[200],
		height: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		overflowY: 'scroll',
	},
	dropZone: {
		border: `dashed ${grey[500]}`,
		padding: '5px',
		margin: '5px',
	},
	imageList: {
		width: 500,
		height: 450,
	},
	fileIcon: {
		width: '50%',
		height: '50%',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	stopShareImage: {
		position: 'absolute',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
}));

const LibraryContent = () => {
	const classes = useStyles();
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [uploadedImages, setUploadedImages] = useRecoilState(uploadedImagesState);
	const [sharedImage, setSharedImage] = useRecoilState(sharedImageState);
	const [sharedAudios, setSharedAudios] = useRecoilState(sharedAudiosState);
	const [shareCanvasImg, setShareCanvasImg] = useRecoilState(shareCanvasImgState);
	const [filesUploaded, setNewFile] = useRecoilState(addNewFile);
	const [uploadingFile, setUploadingFile] = useState(false);
	const { messages, sendMessage } = useChat();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [currentTab, setCurrentTab] = useState(2);

	const showError = (message) => {
		enqueueSnackbar(message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			action: (key) => (
				<Button onClick={onClickDismiss(key)}>
					<Close color={'white'}></Close>
				</Button>
			),
			preventDuplicate: true,
		});
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();

			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');
			reader.onload = () => {
				const binaryStr = reader.result;
			};
			reader.readAsDataURL(file);
			try {
				setUploadingFile(true);
				const file = uploadFile(file);
				if (file) {
					setNewFile(file);
				}
				setUploadingFile(false);
			} catch (e) {
				console.log('error', e);
				setUploadingFile(false);
			}
		});
	}, []);

	const onImgClick = (img) => {
		if (img.isImage) {
			if (img.key === sharedImage.key) {
				stopShareImage();
			} else {
				setSharedImage(img);
				setShareCanvasImg(img.asset);
				const shareImgMessage = {
					type: CHAT_EVENTS.SHARE_S3_IMAGE,
					img: img.asset,
				};
				sendMessage(shareImgMessage);
			}
		} else if (img.isAudio) {
			const tempAudios = [...sharedAudios];
			if (!sharedAudios.some((x) => x.key === img.key)) {
				tempAudios.push(img);
				setSharedAudios(tempAudios);
				const shareImgMessage = {
					type: CHAT_EVENTS.SHARE_S3_AUDIO,
					sharedAudio: tempAudios,
				};
				sendMessage(shareImgMessage);
			}
		}
	};

	const stopShareImage = () => {
		setShareCanvasImg(null);
		setSharedImage(null);
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_IMAGE,
			img: null,
		};
		sendMessage(shareImgMessage);
	};

	const showImageList = () => {
		return (
			<ImageList
				rowHeight={160}
				// className={classes.imageList}
				cols={2}>
				{uploadedImages.map((file) => (
					<ImageListItem
						onClick={() => {
							onImgClick(file);
						}}
						key={file.asset}
						cols={1}>
						{file.isImage ? (
							<img src={file.asset} alt={file.asset} />
						) : (
							<InsertDriveFile className={classes.fileIcon}></InsertDriveFile>
						)}
						{file.isImage && file.key === sharedImage?.key && (
							<ImageListItemBar
								title="Stop Sharing"
								subtitle={
									<StopScreenShare
										className={classes.stopShareImage}
										onClick={stopShareImage}></StopScreenShare>
								}
								position="below"
							/>
						)}
					</ImageListItem>
				))}
			</ImageList>
		);
	};

	const showDropZone = () => {
		return (
			<Dropzone onDrop={onDrop}>
				{({ getRootProps, getInputProps }) => (
					<section className={classes.dropZone}>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<p>Drag 'n' drop some files here, or click to select files</p>
						</div>
					</section>
				)}
			</Dropzone>
		);
	};

	return (
		<Box className={classes.folderTree}>
			<LibraryFolders></LibraryFolders>
			{currentLibraryBreadCrumb.length > 0 && <LibraryDropZone />}
		</Box>
	);
};

export default LibraryContent;
