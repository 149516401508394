import { atom } from 'recoil';

export const localShareActiveState = atom({
	key: 'localShareActiveState',
	default: false,
});
export const localStreamState = atom({
	key: 'localStreamState',
	default: null,
});

export const musicShareActiveState = atom({
	key: 'musicShareActiveState',
	default: false,
});

export const shareActiveState = atom({
	key: 'shareActiveState',
	default: false,
});

export const shareStreamState = atom({
	key: 'shareStreamState',
	default: null,
});

export const musicShareStreamState = atom({
	key: 'musicShareStreamState',
	default: null,
});

export const shareCanvasImgState = atom({
	key: 'shareCanvasImgState',
	default: null,
});

export const shareCanvasImgTempState = atom({
	key: 'shareCanvasImgTempState',
	default: '',
});

export const shareDrawIntervalState = atom({
	key: 'shareDrawIntervalState',
	default: '',
});

export const shareTempImageReadyState = atom({
	key: 'shareTempImageReadyState',
	default: true,
});

export const alreadySharedImageIdsState = atom({
	key: 'alreadySharedImageIdsState',
	default: [],
});

export const showImageUploadState = atom({
	key: 'showImageUploadState',
	default: false,
});

export const showFileManagerState = atom({
	key: 'showFileManagerState',
	default: false,
});

export const uploadedImagesState = atom({
	key: 'uploadedImages',
	default: [],
});

export const sharedImageState = atom({
	key: 'sharedImage',
	default: null,
});

export const ruleImageState = atom({
	key: 'ruleImageState',
	default: null,
});

export const sharedAudiosState = atom({
	key: 'sharedAudios',
	default: [],
});
