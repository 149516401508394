import { Auth } from 'aws-amplify';
import Axios from 'axios';

import { APIS } from '../constants';

const libraryManagerApi = APIS.LIBRARY_MANAGER;
const generateConfig = async () => {
	const currentSession = await Auth.currentSession();

	return {
		headers: {
			Accept: '*/*',
			Authorization: currentSession.getIdToken().getJwtToken(),
			'content-type': '',
			'X-API-KEY': libraryManagerApi.token,
		},
	};
};

export const getLibraryFile = async (name, isPublic) => {
	alert('!hola!!!');
	console.log('adios');
	const config = await generateConfig();
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = !isPublic ? currentUserInfo.username : undefined;

	return await Axios.get(libraryManagerApi.urls.getLibraryFile(name, userId), config);
};

export const createLibrary = async (libraryEntry) => {
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = await generateConfig();

	const response = await Axios.post(libraryManagerApi.urls.createLibrary, libraryEntry, config);
	return response.data.body;
};

export const getUserLibraries = async () => {
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = await generateConfig();
	const response = await Axios.get(libraryManagerApi.urls.getLibraries(userId), config);
	return JSON.parse(response.data.body).libraries;
};
