import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { grey } from '@material-ui/core/colors/';
import {
	makeStyles,
	AppBar,
	Backdrop,
	Button,
	CircularProgress,
	Drawer,
	IconButton,
	Toolbar,
	Typography,
} from '@material-ui/core';
import { ArrowBack, Close, Save } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { LIBRARY_TYPE } from '../../common/constants';
import { uploadLibrary } from '../../common/services/libraryFilesRequests';
import { createLibrary } from '../../common/services/libraryRequests';
import {
	currentLibraryBreadCrumbState,
	privateLibrariesState,
	publicLibrariesContentState,
	publicLibrariesState,
	showLibrariesManagerState,
	uploadingLibraryFileState,
	privateLibrariesContentState,
	rulesLibraryState,
} from '../../common/recoil/Library/libraryAtom';
import Libraries from './components/Libraries';
import { currentLibraryTypeState } from '../../common/recoil/Library/libraryAtom';
import { currentLibraryContentSelector } from '../../common/recoil/Library/librarySelector';

const useStyles = makeStyles((theme) => ({
	drawer: {
		left: '100px',
		'& .MuiPaper-root': {
			left: '73px',
		},
	},
	root: {
		minWidth: '500px',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbar: {
		justifyContent: 'space-between',
	},
}));

const LibrariesManager = () => {
	const classes = useStyles();
	const [showLibrariesManager, setShowLibrariesManager] =
		useRecoilState(showLibrariesManagerState);
	const [uploadingFile, setUploadingFile] = useRecoilState(uploadingLibraryFileState);
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [currentLibraryContent, setCurrentLibraryContent] = useRecoilState(
		currentLibraryContentSelector
	);
	const [privateLibraries, setPrivateLibraries] = useRecoilState(privateLibrariesState);
	const [publicLibraries, setPublicLibraries] = useRecoilState(publicLibrariesState);
	const [rulesLibrary, setRulesLibrary] = useRecoilState(rulesLibraryState);
	const [publicLibrariesContent, setPublicLibrariesContent] = useRecoilState(
		publicLibrariesContentState
	);
	const [privateLibrariesContent, setPrivateLibrariesContent] = useRecoilState(
		privateLibrariesContentState
	);

	const currentLibraryType = useRecoilValue(currentLibraryTypeState);

	const { t, i18n } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		if (currentLibraryContent) {
			const isPrivate = currentLibraryType == LIBRARY_TYPE.PRIVATE;
			if (isPrivate) {
				const library = privateLibrariesContent.some(
					(x) => x.name === currentLibraryContent.name
				);
				if (!library) {
					setPrivateLibrariesContent([...privateLibrariesContent, currentLibraryContent]);
				}
			} else {
				const library = publicLibrariesContent.some(
					(x) => x.name === currentLibraryContent.name
				);

				if (!library) {
					setPublicLibrariesContent([...publicLibrariesContent, currentLibraryContent]);
				}
			}
		}
	}, [currentLibraryContent]);

	const onGoBack = () => {
		const tempBreadCrumb = [...currentLibraryBreadCrumb];
		tempBreadCrumb.pop();
		setCurrentLibraryBreadCrumb(tempBreadCrumb);
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const showSuccess = (message) => {
		enqueueSnackbar(message, {
			variant: 'success',
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			action: (key) => (
				<Button onClick={onClickDismiss(key)}>
					<Close color={'white'}></Close>
				</Button>
			),
			preventDuplicate: true,
		});
	};

	const saveLibrary = async () => {
		const currentUserInfo = await Auth.currentUserInfo();

		const isPrivate = currentLibraryType == LIBRARY_TYPE.PRIVATE;
		const isPublic = currentLibraryType == LIBRARY_TYPE.PUBLIC;
		const isRule = currentLibraryType == LIBRARY_TYPE.RULES;
		let currentLibraryEntry;
		if (isPrivate) {
			currentLibraryEntry = privateLibraries.find(
				(x) => x.name === currentLibraryContent.name
			);
		} else if (isPublic) {
			currentLibraryEntry = publicLibraries.find(
				(x) => x.name === currentLibraryContent.name
			);
		} else {
			currentLibraryEntry = rulesLibrary.find((x) => x.name === currentLibraryContent.name);
		}
		if (!currentLibraryEntry) {
			currentLibraryEntry = {
				name: currentLibraryContent.name,
				userId: isPrivate ? currentUserInfo.username : isPublic ? 'public' : 'rules',
			};
		}
		const alreadyInDb = !!currentLibraryEntry.libraryKey;
		const response = await uploadLibrary(
			currentLibraryContent,
			isPrivate,
			currentLibraryEntry.libraryKey,
			isRule
		);
		if (!alreadyInDb) {
			currentLibraryEntry = { ...currentLibraryEntry };
			currentLibraryEntry.key = response.key;
			const dbLibrary = await createLibrary(currentLibraryEntry);
			if (isPrivate) {
				const newLibraryEntry = [
					...privateLibraries.filter((x) => x.name !== currentLibraryContent.name),
					currentLibraryEntry,
				];
				setPrivateLibraries(newLibraryEntry);
			} else if (isPublic) {
				const newLibraryEntry = [
					...publicLibraries.filter((x) => x.name !== currentLibraryContent.name),
					currentLibraryEntry,
				];
				setPublicLibraries(newLibraryEntry);
			} else {
				const newLibraryEntry = [
					...rulesLibrary.filter((x) => x.name !== currentLibraryContent.name),
					currentLibraryEntry,
				];
				setRulesLibrary(newLibraryEntry);
			}
		}
		showSuccess('Ok');
	};

	return (
		<>
			{showLibrariesManager && (
				<Drawer
					anchor="left"
					className={classes.drawer}
					open={showLibrariesManager}
					variant="permanent"
					onClose={() => setShowLibrariesManager(false)}>
					<AppBar position="static">
						<Toolbar className={classes.toolbar}>
							{currentLibraryBreadCrumb?.length > 0 && (
								<IconButton
									edge="start"
									className={classes.menuButton}
									color="inherit"
									onClick={onGoBack}
									aria-label="back">
									<ArrowBack />
								</IconButton>
							)}
							<Typography variant="h6">{t('LibrariesManager.title')}</Typography>
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								onClick={saveLibrary}
								aria-label="back">
								<Save />
							</IconButton>
							<IconButton
								edge="end"
								className={classes.menuButton}
								color="inherit"
								onClick={() => setShowLibrariesManager(false)}
								aria-label="close">
								<Close />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Libraries />
					<Backdrop className={classes.backdrop} open={uploadingFile}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</Drawer>
			)}
		</>
	);
};

export default LibrariesManager;
