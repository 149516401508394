/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:3aba69c8-1c62-4134-a49c-99c08fe45ccf",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_QdCsshiTT",
    "aws_user_pools_web_client_id": "2tlo9c28tqmenodlqfs9o1v86n",
    "oauth": {}
};


export default awsmobile;
