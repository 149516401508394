import React, { useEffect } from 'react';
import {
	makeStyles,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CHAT_EVENTS, CAMERA_TYPE, IS_LOCALHOST, FAKE_STREAM } from './../../common/constants';
import { useChat } from './../../common/hooks';
import { userIdState } from '../../common/recoil/UserInfo/atoms';
import {
	cameraControlState,
	cameraViewTypeState,
	currentCameraLayoutState,
	highlightedUsersState,
} from './../../common/recoil/Cameras/atom';
import {
	roomConnectionsState,
	remoteMediaStreamsState,
	remoteNamesState,
} from './../../common/recoil/websocket/atoms';

import { remoteMediaStreamsSelector } from './../../common/recoil/websocket/selectors';

const useStyles = makeStyles(() => ({}));

const CamerasBoard = () => {
	const [cameraControl, setCameraControl] = useRecoilState(cameraControlState);
	const [cameraViewType, setCameraViewType] = useRecoilState(cameraViewTypeState);
	const [currentCameraLayout, setCurrentCameraLayout] = useRecoilState(currentCameraLayoutState);
	const [highlightedUsers, setHighlightedUsers] = useRecoilState(highlightedUsersState);
	const [remoteMediaStreams, setRemoteMediaStreamsSelector] = useRecoilState(
		remoteMediaStreamsSelector
	);
	const [remoteNames, setRemoteNames] = useRecoilState(remoteNamesState);
	const streamConnections = useRecoilValue(remoteMediaStreamsState);
	const userId = useRecoilValue(userIdState);
	const { t, i18n } = useTranslation();
	const { messages, sendMessage } = useChat(userId);

	const handleCameraTypeChange = (event, value) => {
		setCameraViewType(parseInt(value));
	};

	const handleHighlightUser = (event, value) => {
		//setCameraViewType(parseInt(value));
	};

	const updateCameraLayout = () => {
		const cameraLaoyout = {
			type: cameraViewType,
			highlightedUsers,
		};
		setCurrentCameraLayout(cameraLaoyout);
		const cameraLayoutMessage = {
			type: CHAT_EVENTS.CAMERA_LAYOUT,
			layout: cameraLaoyout,
		};
		sendMessage(cameraLayoutMessage);
	};

	const addFakeStream = () => {
		console.log('current remoteMediaStreams', remoteMediaStreams);
		const name = `REMOTE-${remoteMediaStreams.length}`;
		setRemoteMediaStreamsSelector({
			id: name,
			streamMedia: {
				type: FAKE_STREAM,
			},
			name: name,
		});
		const newName = {
			id: name,
			name: name,
		};
		setRemoteNames([newName, ...remoteNames]);
	};

	const removeFakeStream = () => {};

	const highlightedUserCheckToggle = (event, value) => {
		const currentId = event.currentTarget.defaultValue;
		if (value) {
			setHighlightedUsers([currentId, ...highlightedUsers]);
		} else {
			const currentHighLighted = [...highlightedUsers];
			setHighlightedUsers([...highlightedUsers.filter((x) => x !== currentId)]);
		}
	};

	return cameraControl ? (
		<Box>
			<FormControl component="fieldset">
				<FormLabel component="legend">{t('camerasBoard.cameraType.title')}</FormLabel>
				<RadioGroup
					row
					aria-label="cameraType"
					name="cameraType"
					value={cameraViewType}
					onChange={handleCameraTypeChange}>
					<FormControlLabel
						value={CAMERA_TYPE.DEFAULT}
						control={<Radio />}
						labelPlacement="top"
						label={t('camerasBoard.cameraType.default')}
					/>
					<FormControlLabel
						value={CAMERA_TYPE.HIGHLIGHTED_USERS}
						control={<Radio />}
						labelPlacement="top"
						label={t('camerasBoard.cameraType.highlighted_users')}
					/>
				</RadioGroup>
			</FormControl>
			{cameraViewType === CAMERA_TYPE.HIGHLIGHTED_USERS && (
				<FormControl component="fieldset">
					<FormLabel component="legend">
						{t('camerasBoard.highlightedLayout.title')}
					</FormLabel>
					<FormGroup
						aria-label="highlightedUser"
						name="highlightedUser"
						value={cameraViewType}
						onChange={handleHighlightUser}>
						<FormControlLabel
							value={userId}
							control={<Checkbox />}
							labelPlacement="end"
							label="Local user"
							onChange={highlightedUserCheckToggle}
						/>
						{streamConnections.map((streamConnection) => {
							return (
								<FormControlLabel
									key={streamConnection.id}
									value={streamConnection.id}
									control={<Checkbox />}
									labelPlacement="end"
									label={
										remoteNames.find((x) => x.id === streamConnection.id)?.name
									}
									onChange={highlightedUserCheckToggle}
								/>
							);
						})}
					</FormGroup>
				</FormControl>
			)}
			<Button onClick={updateCameraLayout}>Update</Button>
			{IS_LOCALHOST && (
				<>
					<Button onClick={addFakeStream}>Add fake stream</Button>
					<Button onClick={removeFakeStream}>Remove fake stream</Button>
				</>
			)}
		</Box>
	) : (
		<></>
	);
};

export default CamerasBoard;
