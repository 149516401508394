import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import MultiStreamsMixer from 'multistreamsmixer';

import {
	musicShareActiveState,
	musicShareStreamState,
} from '../recoil/Share/atom';

function useShareMusic(localMedia) {
	const [share, setShare] = useRecoilState(musicShareStreamState);
	const [shareActive, setShareActive] = useRecoilState(musicShareActiveState);

	const [constraints, setConstraints] = useState({
		audio: true,
		video: true,
	});

	useEffect(() => {
		if (shareActive) {
			const getMediaStream = async () => {
				try {
					const mediaStream = await navigator.mediaDevices.getDisplayMedia(
						constraints
					);
					// localMedia.getTracks().forEach((track) => {
					// 	console.log(track);
					// 	mediaStream.addTrack(track);
					// });
					const audioStream = new MediaStream(
						mediaStream.getAudioTracks()
					);
					audioStream.width = localMedia.width;
					audioStream.height = localMedia.height;
					const mixer = new MultiStreamsMixer([
						localMedia,
						audioStream,
					]);

					mixer.frameInterval = 1;
					mixer.startDrawingFrames();
					await setShare(mixer.getMixedStream());
				} catch (error) {
					console.error('error', error);
					setShareActive(false);
				}
			};
			getMediaStream();
		}
	}, [shareActive]);

	return share;
}

export default useShareMusic;
