import React, { useEffect, useRef } from 'react';
import { makeStyles, Box, IconButton } from '@material-ui/core';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Cancel } from '@material-ui/icons';

import { CHAT_EVENTS } from '../../../common/constants';
import {
	currentCameraLayoutState,
	isShareHighlightedState,
} from './../../../common/recoil/Cameras/atom';
import { remoteDiceResultsState } from './../../../common/recoil/chatMessages/atoms';
import {
	ruleImageState,
	shareCanvasImgState,
	sharedAudiosState,
	sharedImageState,
} from './../../../common/recoil/Share/atom';
import { isShareActiveSelector } from './../../../common/recoil/Share/selectors';
import {
	userAvatarState,
	diceResultsState,
	userIdState,
	videoOnState,
	microOnState,
} from './../../../common/recoil/UserInfo/atoms';
import {
	remoteAvatarsState,
	remoteMediaStreamsState,
	remoteNamesState,
	remoteVideosPlayingState,
	remoteAudiosPlayingState,
} from './../../../common/recoil/websocket/atoms';
import { SharingArea, UserCameraContainer, VideoWebrtc } from './../../index';
import { useChat } from '../../../common/hooks';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		position: 'fixed',
		width: 'calc(100% - 73px)',
		height: '100%',
		left: '73px',
		backgroundImage: 'url(/images/backgrounds/robotta1.jpg)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	highLighted: {
		display: 'flex',
		flexDirection: 'row',
		width: (props) => getAreaWidth(props.highLighted, props.expectators, '80%'),
		height: '100%',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
		alignContent: 'space-around',
	},
	expectators: {
		display: 'flex',
		flexDirection: 'column',
		width: (props) => getAreaWidth(props.expectators, props.highLighted, '20%'),
		height: '100%',
	},
	primary: {
		height: (props) => getActorHeight(props.highLighted, props.wrapperRef),
		width: (props) => getActorWidth(props.highLighted, props.wrapperRef),
		display: 'flex',
		alignItems: 'center',
	},
	secondary: {
		height: (props) => `${100 / props.expectators}%`,
	},
	sharingHidden: {
		visibility: 'hidden',
		position: 'absolute',
	},
	sharingImg: {
		maxWidth: '100%',
		maxHeight: '100%',
		objectFit: 'contain',
	},
	sharingImgContainer: {
		display: 'flex',
	},
	sharingImgContainerprimary: {
		display: 'flex',
		justifyContent: 'center',
		height: (props) => getActorHeight(props.highLighted, props.wrapperRef),
		width: (props) => getActorWidth(props.highLighted, props.wrapperRef),
	},
	sharingImgContainersecondary: {
		display: 'flex',
		justifyContent: 'center',
		height: (props) => `${100 / props.expectators}%`,
	},
});

const getAreaWidth = (selfCount, otherCount, width) => {
	if (selfCount === 0) {
		return '0%';
	} else if (otherCount === 0) {
		return '100%';
	} else {
		return width;
	}
};

const getActorHeight = (highLighted, wrapperRef) => {
	return getActorViewSize(highLighted, wrapperRef).height;
};
const getActorWidth = (highLighted, wrapperRef) => {
	return getActorViewSize(highLighted, wrapperRef).width;
};
const getActorViewSize = (highLighted, wrapperRef) => {
	const wrapperWidth = wrapperRef?.current?.clientWidth || 0;
	const wrapperHeight = wrapperRef?.current?.clientHeight || 0;
	const margin = 20;
	let width = '100%';
	let height = '100%';
	switch (highLighted) {
		case 1:
			width = '100%';
			height = '100%';
			break;
		case 2:
			width = '50%';
			height = '100%';
			break;
		case 3:
			width = `${Math.abs(wrapperWidth / 2) - margin}px`;
			height = `${Math.abs(wrapperHeight / 2) - margin}px`;
			break;
		case 4:
			width = '50%';
			height = '50%';
			break;
		case 5:
			width = `${Math.abs(wrapperWidth / 3) - margin}px`;
			height = `${Math.abs(wrapperHeight / 2) - margin}px`;
			break;
		case 6:
			width = '33%';
			height = '50%';
			break;
		case 7:
			width = '33%';
			height = '33%';
			break;
		case 8:
			width = '33%';
			height = '33%';
			break;
		case 9:
			width = '33%';
			height = '33%';
			break;
	}

	const widthDivider = Math.min(
		Math.floor(highLighted / 5) +
			Math.floor(highLighted / 2) +
			Math.ceil((1 - highLighted) / 3) +
			1,
		3
	);

	width = `${Math.abs(wrapperWidth / widthDivider) - margin}px`;
	height = `${Math.abs(wrapperHeight / (highLighted > 2 ? 2 : 1)) - margin}px`;
	return {
		height,
		width,
	};
};

const HighlightedCameras = ({ localMedia }) => {
	const wrapperRef = useRef();
	const currentCameraLayout = useRecoilValue(currentCameraLayoutState);
	const localAvatar = useRecoilValue(userAvatarState);
	const diceResults = useRecoilValue(diceResultsState);
	const id = useRecoilValue(userIdState);
	const streamConnections = useRecoilValue(remoteMediaStreamsState);
	const remoteDiceResults = useRecoilValue(remoteDiceResultsState);
	const remoteNames = useRecoilValue(remoteNamesState);
	const remoteAvatars = useRecoilValue(remoteAvatarsState);
	const isShareHighlighted = useRecoilValue(isShareHighlightedState);
	const isShareActive = useRecoilValue(isShareActiveSelector);
	const localVideoPlaying = useRecoilValue(videoOnState);
	const localMicroPlaying = useRecoilValue(microOnState);
	const remoteVideosPlaying = useRecoilValue(remoteVideosPlayingState);
	const remoteAudiosPlaying = useRecoilValue(remoteAudiosPlayingState);
	const [sharedImage, setSharedImage] = useRecoilState(sharedImageState);
	const [sharedAudios, setSharedAudios] = useRecoilState(sharedAudiosState);
	const [canvasImage, setShareCanvasImg] = useRecoilState(shareCanvasImgState);
	const [ruleImage, setRuleImage] = useRecoilState(ruleImageState);

	const { messages, sendMessage } = useChat();

	const isShareHighlightedVisible = isShareHighlighted && (isShareActive || ruleImage !== null);
	const isShareSecondaryVisible = !isShareHighlighted && (isShareActive || ruleImage !== null);

	const highlightedLength =
		currentCameraLayout?.highlightedUsers?.length === 0 && !isShareHighlightedVisible
			? streamConnections.length + 1
			: streamConnections.filter((connection) =>
					currentCameraLayout?.highlightedUsers?.includes(connection.id)
			  ).length +
			  (currentCameraLayout?.highlightedUsers?.includes(id) ? 1 : 0) +
			  (isShareHighlightedVisible ? 1 : 0);

	const expectators =
		1 +
		streamConnections?.length -
		highlightedLength +
		(isShareHighlightedVisible ? 1 : 0) +
		(isShareSecondaryVisible ? 1 : 0);

	const classes = useStyles({
		highLighted: highlightedLength,
		expectators: expectators,
		wrapperRef: wrapperRef,
	});

	const getMargins = (myPos) => {};

	const stopShareImage = () => {
		setShareCanvasImg(null);
		setSharedImage(null);
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_IMAGE,
			img: null,
		};
		sendMessage(shareImgMessage);
	};
	const stopRuleImage = () => {
		setRuleImage(null);
	};

	const getCameras = (highLighted) => {
		const boxClass =
			highLighted ||
			(currentCameraLayout?.highlightedUsers?.length === 0 && !isShareHighlightedVisible)
				? `primary`
				: 'secondary';

		return (
			<>
				{currentCameraLayout?.highlightedUsers?.includes(id) === highLighted && (
					<Box p={0} className={classes[boxClass]}>
						<UserCameraContainer
							avatarUrl={localAvatar?.url}
							diceResults={diceResults}
							mediaStream={localMedia}
							ready={true}
							userId={id || 'local'}
							isLocalUser={true}
							useCameraClass="responsiveCamera"
							isSideBar={
								(!highLighted && currentCameraLayout?.highlightedUsers.length) ||
								isShareHighlightedVisible
							}
							videoPlaying={localVideoPlaying}
							isMicPlaying={localMicroPlaying}
						/>
					</Box>
				)}

				{streamConnections
					.filter(
						(x) => currentCameraLayout?.highlightedUsers?.includes(x.id) === highLighted
					)
					.map((remoteStreamConnection) => {
						return (
							<Box
								p={0}
								key={remoteStreamConnection.id}
								className={classes[boxClass]}>
								<UserCameraContainer
									avatarUrl={
										remoteAvatars.find(
											(x) => x.id === remoteStreamConnection.id
										)?.avatarUrl
									}
									diceResults={
										remoteDiceResults.find(
											(x) => x.id === remoteStreamConnection.id
										)?.results || []
									}
									mediaStream={remoteStreamConnection.streamMedia}
									userId={remoteStreamConnection.id}
									remoteUserName={
										remoteNames.find((x) => x.id === remoteStreamConnection.id)
											?.name || remoteStreamConnection.id
									}
									ready={true}
									useCameraClass="responsiveCamera"
									isSideBar={
										(!highLighted &&
											currentCameraLayout?.highlightedUsers.length) ||
										isShareHighlightedVisible
									}
									videoPlaying={
										remoteVideosPlaying.find(
											(x) => x.id === remoteStreamConnection.id
										)?.isLocalVideoPlaying
									}
									isMicPlaying={
										remoteAudiosPlaying.find(
											(x) => x.id === remoteStreamConnection.id
										)?.isMicPlaying
									}
								/>
							</Box>
						);
					})}
				{((highLighted && isShareHighlightedVisible) ||
					(!highLighted && isShareSecondaryVisible)) && (
					<Box className={classes[`sharingImgContainer${boxClass}`]}>
						{(sharedImage || ruleImage) && (
							<Box position="absolute">
								<IconButton color="secondary">
									<Cancel
										onClick={() => {
											if (ruleImage) {
												stopRuleImage();
											} else {
												stopShareImage();
											}
										}}></Cancel>
								</IconButton>
							</Box>
						)}
						{ruleImage ? (
							<img className={classes.sharingImg} src={ruleImage}></img>
						) : (
							<img className={classes.sharingImg} src={canvasImage}></img>
						)}
					</Box>
				)}
			</>
		);
	};

	return (
		<Box className={classes.root}>
			<Box ref={wrapperRef} className={classes.highLighted}>
				{getCameras(
					currentCameraLayout?.highlightedUsers.length > 0 || isShareHighlightedVisible
				)}
			</Box>
			{(currentCameraLayout?.highlightedUsers.length > 0 || isShareHighlightedVisible) && (
				<Box className={classes.expectators}>{getCameras(false)}</Box>
			)}
			<Box className={classes.sharingHidden}>
				<SharingArea></SharingArea>
			</Box>
		</Box>
	);
};

export default HighlightedCameras;
