import { DICE_TYPES } from './../constants';

const SYMBOLS = {
	NONE: 'none',
	STRIFE: 'strife',
	OPPORTUNITY: 'opportunity',
	SUCCESS: 'success',
	EXPLOSIVE_SUCCESS: 'explosive-success',
	TWO_VALUES: 'two_Values',
};

const getDiceFace = (notation, result) => {
	const isNone =
		(notation === 6 && result === 1) || (notation === 12 && (result === 1 || result === 2));
	const isStrife =
		(notation === 6 && (result === 2 || result === 4 || result === 6)) ||
		(notation === 12 && (result === 6 || result === 7 || result === 11));
	const isOpportunity =
		(notation === 6 && (result === 2 || result === 3)) ||
		(notation === 12 && (result === 3 || result === 4 || result === 5 || result === 10));
	const isSuccess =
		(notation === 6 && (result === 4 || result === 5)) ||
		(notation === 12 &&
			(result === 6 || result === 7 || result === 8 || result === 9 || result === 10));
	const isExplosiveSuccess =
		(notation === 6 && result === 6) || (notation === 12 && (result === 11 || result === 12));
	const hasTwoValues =
		(notation === 6 && (result === 2 || result === 4 || result === 6)) ||
		(notation === 12 && (result === 6 || result === 7 || result === 10 || result === 11));

	return {
		isNone,
		isStrife,
		isOpportunity,
		isSuccess,
		isExplosiveSuccess,
		hasTwoValues,
	};
};

export const getDiceClasses = (notation, result) => {
	const diceFace = getDiceFace(notation, result);
	return `result_${result}  ${diceFace.isNone ? SYMBOLS.NONE : ''} 
    ${diceFace.isStrife ? SYMBOLS.STRIFE : ''}
    ${diceFace.isOpportunity ? SYMBOLS.OPPORTUNITY : ''}
    ${diceFace.isSuccess ? SYMBOLS.SUCCESS : ''}
    ${diceFace.isExplosiveSuccess ? SYMBOLS.EXPLOSIVE_SUCCESS : ''}
    ${diceFace.hasTwoValues ? SYMBOLS.TWO_VALUES : ''}`;
};

export const getDicesResult = (results, shownResults = []) => {
	const symbolValues = {
		opportunities: 0,
		strifes: 0,
		success: 0,
		explosiveSuccess: 0,
	};
	let someResult = false;
	const explosiveNotations = [];
	results.map((result) => {
		if (result?.type === DICE_TYPES.L5R) {
			const faceResult = getDiceFace(result.notation, result.value);
			if (faceResult.isExplosiveSuccess) {
				symbolValues.explosiveSuccess++;
				if (!shownResults.some((x) => x.date === result.date)) {
					explosiveNotations.push(result.notation);
				}
				someResult = true;
			}
			if (faceResult.isStrife) {
				symbolValues.strifes++;
				someResult = true;
			}
			if (faceResult.isSuccess) {
				symbolValues.success++;
				someResult = true;
			}
			if (faceResult.isOpportunity) {
				symbolValues.opportunities++;
				someResult = true;
			}
		}
	});

	return {
		explosiveNotations,
		someResult,
		symbolValues,
		text: `${symbolValues.success} éxitos ${symbolValues.explosiveSuccess} éxitos relanzable ${symbolValues.opportunities} oportunidades y ${symbolValues.strifes} conflitcos `,
	};
};
