import React, { useState } from 'react';
import { makeStyles, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { CreateNewFolder, Folder, InsertDriveFile } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CHAT_EVENTS, LIBRARY_TYPE, LIBRARY_ITEMS_TYPES } from '../../../common/constants';
import {
	currentLibraryTypeState,
	currentLibraryBreadCrumbState,
	showCreateFolderDialogState,
	showCreateLibraryDialogState,
	showLibrariesManagerState,
} from '../../../common/recoil/Library/libraryAtom';
import {
	ruleImageState,
	shareCanvasImgState,
	sharedAudiosState,
	sharedImageState,
} from '../../../common/recoil/Share/atom';
import { useChat } from '../../../common/hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		cursor: 'pointer',
		overflow: 'hidden',
	},
	createFolder: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	img: {
		maxWidth: '100%',
	},
}));

const LibraryItem = ({ file, isLibraryLevel, title, type }) => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [showCreateLibraryDialog, setShowCreateLibraryDialog] = useRecoilState(
		showCreateLibraryDialogState
	);
	const [showCreateFolderDialog, setShowCreateFolderDialog] = useRecoilState(
		showCreateFolderDialogState
	);
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [showLibrariesManager, setShowLibrariesManager] =
		useRecoilState(showLibrariesManagerState);
	const [sharedImage, setSharedImage] = useRecoilState(sharedImageState);
	const [ruleImage, setRuleImage] = useRecoilState(ruleImageState);
	const [sharedAudios, setSharedAudios] = useRecoilState(sharedAudiosState);
	const [shareCanvasImg, setShareCanvasImg] = useRecoilState(shareCanvasImgState);
	const currentLibraryType = useRecoilValue(currentLibraryTypeState);

	const { messages, sendMessage } = useChat();

	const isCreateFolder = type === LIBRARY_ITEMS_TYPES.CREATE_FOLDER;
	const isFolder = type === LIBRARY_ITEMS_TYPES.FOLDER || type === undefined;
	const isFile = type === LIBRARY_ITEMS_TYPES.FILE;

	const handleClick = () => {
		if (isCreateFolder) {
			createNew();
		} else if (isFile) {
			toggleElement();
		} else {
			const previousBreadCrumb = [...currentLibraryBreadCrumb];
			const nextBreadCrumb = [...previousBreadCrumb, title];
			setCurrentLibraryBreadCrumb(nextBreadCrumb);
		}
	};

	const toggleElement = () => {
		if (file.isImage) {
			if (file?.key === sharedImage?.key) {
				stopShareImage();
			} else {
				if (currentLibraryType === LIBRARY_TYPE.RULES) {
					if (file.key === ruleImage) {
						setRuleImage(null);
					} else {
						setRuleImage(file.url);
						setShowLibrariesManager(false);
					}
				} else {
					setSharedImage(file);
					setShareCanvasImg(file.url);
					const sharefileMessage = {
						type: CHAT_EVENTS.SHARE_S3_IMAGE,
						img: file.url,
					};
					sendMessage(sharefileMessage);
				}
			}
		} else if (file.isAudio) {
			const tempAudios = [...sharedAudios];
			if (!sharedAudios.some((x) => x.key === file.key)) {
				tempAudios.push(file);
				setSharedAudios(tempAudios);
				const sharefileMessage = {
					type: CHAT_EVENTS.SHARE_S3_AUDIO,
					sharedAudio: tempAudios,
				};
				sendMessage(sharefileMessage);
			}
		}
	};

	const stopShareImage = () => {
		setShareCanvasImg(null);
		setSharedImage(null);
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_IMAGE,
			img: null,
		};
		sendMessage(shareImgMessage);
	};

	const createNew = () => {
		if (isLibraryLevel) {
			setShowCreateLibraryDialog(true);
		} else {
			setShowCreateFolderDialog(true);
		}
	};

	const showCreateNewFolder = () => {
		return (
			<IconButton>
				<CreateNewFolder />
			</IconButton>
		);
	};

	const renderFile = () => {
		if (file.isImage) {
			return <img className={classes.img} src={file.url} alt={file.name} />;
		} else {
			return <InsertDriveFile></InsertDriveFile>;
		}
	};

	return (
		<Paper
			onClick={handleClick}
			className={`${classes.paper} ${isCreateFolder ? classes.createFolder : ''}`}>
			{isFolder && <Folder />}
			{isFile && renderFile()}
			<Tooltip title={title}>
				<Typography>{title}</Typography>
			</Tooltip>
			{isCreateFolder && showCreateNewFolder()}
		</Paper>
	);
};

export default LibraryItem;
