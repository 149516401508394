import { selector } from 'recoil';

import { dicesBonusListState } from './gameSystemAtom';

export const addDiceBonusSelector = selector({
	key: 'addDiceBonusSelector',
	get: ({ get }) => get(dicesBonusListState),
	set: ({ get, set }, newElement) => {
		const current = get(dicesBonusListState);
		const newValue = [newElement, ...current.filter((x) => x.name !== newElement.name)];

		set(dicesBonusListState, newValue);
	},
});
