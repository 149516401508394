import React from 'react';
import WebSocketClient from 'websocket';

import { Game } from '../index';

class Main extends React.Component {
	client = null;

	constructor() {
		super();
		console.log(this.client);
	}

	render() {
		return <Game></Game>;
	}
}

export default Main;
